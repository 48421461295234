import BaseModel from "../Core/BaseModel";

export default class FacilityModel extends BaseModel {
    category?: any;
    name?: string;
    image_path?: string;


    is_active?: boolean;
    //Request
    file?: File;
}

export enum FacilityCategory {
    educational_facilities,
    classroom_facilities,
    laboratory_facilities,
    boarding_facilities,
    sports_facilities,
    performing_arts_facilities,
    digital_facilities,
    food_and_catering_facilities,
    safety_and_security_facilities,
    medical_facilities,
    other_infrastructure
}