import Axios from "axios";
import { createBrowserHistory } from "history";
import BaseComponent from "../Core/BaseComponent";
import { BaseResponse } from "../Core/BaseResponse";
import BaseState from "../Core/BaseState";
import BaseViewModel from "../Core/BaseViewModel";
import Helper from "../Core/Helper";
import SessionHelper from "../Core/SessionHelper";
import PaginationModel from "../Models/PaginationModel";
//import PropTypes from "prop-types";
class PropType<T, B> {
  data: T = null as any;
  onChangeUpdate: any;
  body?: B;
}
export default class Pagination<T, B> extends BaseComponent<
  PropType<PaginationModel<T>, B>,
  PaginationViewModel<T>
> {
  constructor(props: any) {
    super(props);
    const { data } = this.props;
    this.state = new BaseState<PaginationViewModel<T>>(
      new PaginationViewModel<T>()
    );
    this.state.model.pagination = data;
  }
  componentDidMount(): void {
    window.onpopstate = () => {
      this.GetHistoryData();
    };
  }
  componentWillUnmount() {
    // alert("un");
  }
  GetHistoryData() {
    var pathData = Helper.GetBasePathData();
    let data_url = `${this.state.model.pagination.path}?page=${pathData.page}&per_page=${pathData.per_page}`;
    this.GetByHistory(data_url);
    this.UpdateViewModel();
  }
  OnChangeUpdate = () => {
    this.props.onChangeUpdate(this.state.model);
  };
  get SetHeaders(): any {
    const token = SessionHelper.GetToken();
    var headers = { headers: { Authorization: `Bearer ${token}` } };
    return headers;
  }
  ModelConvertToUrl(model: B | any): string {
    if (!model) return "";
    let url = "&";
    for (var key in model) {
      url += key + "=" + model[key] + "&";
    }
    return url.slice(0, -1);
  }
  GetByPage(url: string, active: boolean = false) {
    if (!url) return;
    if (active) return;
    this.ShowDataLoader(true);
    this.OnChangeUpdate();
    var pathData = Helper.GetBasePathData();
    url =
      `${url}&per_page=${pathData.per_page}` +
      this.ModelConvertToUrl(this.props.body);
    Axios.get(url, this.SetHeaders)
      .then((result) => {
        this.ShowDataLoader(false);
        this.OnChangeUpdate();
        let res = result.data as BaseResponse<any>;
        if (res.is_success) {
          this.state.model.pagination = res.data as PaginationModel<T>;
          this.UpdateViewModel();
          this.OnChangeUpdate();
          var pathDataByUrl = Helper.GetBasePathData(url);
          let history = createBrowserHistory();
          var tmpUrl = `?page=${pathDataByUrl.page}&per_page=${pathDataByUrl.per_page}`;
          history.push(tmpUrl);
        }
      })
      .catch((error) => {
        this.ShowDataLoader(false);
        this.OnChangeUpdate();
        this.ShowToast(error.message, "warning");
      });
  }
  GetByHistory(url: string) {
    if (!url) return;
    this.ShowDataLoader(true);
    this.OnChangeUpdate();
    url = url + this.ModelConvertToUrl(this.props.body);
    Axios.get(url, this.SetHeaders)
      .then((result) => {
        this.ShowDataLoader(false);
        this.OnChangeUpdate();
        let res = result.data as BaseResponse<any>;
        if (res.is_success) {
          this.state.model.pagination = res.data as PaginationModel<T>;
          this.UpdateViewModel();
          this.OnChangeUpdate();
        }
      })
      .catch((error) => {
        this.ShowDataLoader(false);
        this.OnChangeUpdate();
        this.ShowToast(error.message, "warning");
      });
  }
  render() {
    const model = this.state.model.pagination;
    function createMarkup(html: string) {
      return {
        __html: html,
      };
    }
    return (
      <ul className="pagination mt-4">
        {model.links.map((link, index) => {
          var className = "mr-1 waves-effect";
          if (link.active) {
            className = "mr-1 active";
          }
          return (
            <li key={index} className={className}>
              <a
                dangerouslySetInnerHTML={createMarkup(link.label)}
                onClick={() => this.GetByPage(link.url, link.active)}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}
class PaginationViewModel<T> extends BaseViewModel {
  pagination: PaginationModel<T> = new PaginationModel<T>();
}
