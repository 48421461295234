import BaseViewModel from "../../Core/BaseViewModel";
import CategoryModel from "../../Models/CategoryModel";

export default class CourseCreateUpdateViewModel extends BaseViewModel {
    slug?: string;
    category_id?: number;
    name?: string;
    eligibility?:string;
    duration_year?:number;
    duration_month?:number;
    image_path?: string;

    categories: CategoryModel[] = [];

    //Request
    file?: File;
}