import BaseViewModel from "../../Core/BaseViewModel";
import CountryModel from "../../Models/CountryModel";

export default class StateCreateUpdateViewModel extends BaseViewModel {
    country_id?:number;
    name?: string;
    abbreviation?: string;
    image_path?: string;

    countries:CountryModel[]=[];

    //Request
    file?: File;
}