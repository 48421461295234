import BaseApi from "../../Core/BaseApi";

export default class StateService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/state/list`, model,null, callBack);
    }


    public static Create(model: any,headers:any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/state/create`, model,headers, callBack);
    }

    public static Update(model: any,headers:any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/state/update`, model,headers, callBack);
    }

    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/state/details`, model,null, callBack);
    }

    public static Delete(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/state/delete`, model, null,callBack);
    }
}