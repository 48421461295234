import BaseViewModel from "../../Core/BaseViewModel";
import CollegeCourseModel from "../../Models/CollegeCourseModel";
import CollegeDepartmentModel from "../../Models/CollegeDepartmentModel";
import DepartmentModel from "../../Models/DepartmentModel";


export default class CollegeDepartmentChildViewModel extends BaseViewModel {

    college_id?: number;
    course_id?: number;
    department_id?: number;
    seats?: number;
    total_fees_amount?: number;
    average_fees_amount?: number;
    admission_fees_amount?: number;
    semester_fees_amount?: number;
    fees_description?: string;

    //Bind
    college_courses: CollegeCourseModel[] = [];
    college_departments: CollegeDepartmentModel[] = [];
    departments: DepartmentModel[] = [];
    all_department: CollegeDepartmentModel[] = [];

}
