import { Component } from 'react'
import BaseState from './BaseState';
import BaseViewModel from './BaseViewModel';


export default abstract class BaseComponentCore<P, M> extends Component<P, BaseState<M>> {

  //hare implament template set up done


  SetCustomeState = (event: any) => {

    const { target: { id, name, value } } = event;
    var propertyName = id;
    if (!propertyName) {
      propertyName = name;
    }
    if (!propertyName) {
      return alert("propertyName undefined")
    }

    Object.assign(this.state.model as M | any, { [propertyName]: value });
    this.setState({
      model: this.state.model
    } as Pick<BaseState<M>, keyof BaseState<M>>);

  }

  SetModel = (value: Partial<M>) => {
    Object.assign(this.state.model as any | M, value);
    this.setState({
      model: this.state.model
    } as Pick<BaseState<M>, keyof BaseState<M>>)

  }

  SetCustomeStateBool = (event: any) => {
    const { target: { id, name, checked } } = event;
    var propertyName = id;
    if (!propertyName) {
      propertyName = name;
    }
    if (!propertyName) {
      return alert("propertyName undefined")
    }

    Object.assign(this.state.model as any | M, { [propertyName]: checked });
    this.setState({
      model: this.state.model
    } as Pick<BaseState<M>, keyof BaseState<M>>);
  }

  UpdateViewModelUnknown(model: BaseViewModel | M) {
    this.setState({
      model: model as unknown as M
    })
  }

  UpdateViewModel() {
    this.setState({
      model: this.state.model
    })
  }

}

