import { ReactNode } from "react";
import BaseComponent from "../../../Core/BaseComponent";

export default class DashboardPage extends BaseComponent<any,any> {

    render(): ReactNode {
        return(
            <>
            <h1>User</h1>
            </>
        )
    }

}