export default class PaginationModel<T> {
    current_page?: number;
    data: T[] = [];
    first_page_url?: string;
    last_page_url?: string;
    last_page?: number;
    total?: number;
    links: PaginationLinkModel[] = [];
    path: string = "";
}


export class PaginationLinkModel {
    url: string = "";
    label?: any;
    active?: boolean;
}