import { ReactNode } from "react";
import CustomImage from "../../../Components/CustomImage";
import GridLoader from "../../../Components/GridLoader";
import MasterControl from "../../../Components/MasterControl";
import Pagination from "../../../Components/Pagination";
import BaseComponent from "../../../Core/BaseComponent";
import BaseCore from "../../../Core/BaseCore";
import { BaseResponse } from "../../../Core/BaseResponse";
import BaseState from "../../../Core/BaseState";
import Helper from "../../../Core/Helper";
import CollegeGalleryService from "../../../DataServices/Super/CollegeGalleryService";
import CollegeGalleryModel from "../../../Models/CollegeGalleryModel";
import CollegeGalleryChildViewModel from "../../../ViewModels/College/CollegeGalleryChildViewModel";
export default class CollegeGalleryChildPage extends BaseComponent<
  any,
  CollegeGalleryChildViewModel
> {
  constructor(props: any) {
    super(props);
    const { data } = props;
    this.state = new BaseState<CollegeGalleryChildViewModel>(data);
  }
  componentDidMount() {
    this.InitializeForm();
  }
  InitializeForm() {
    this.ShowDataLoader(true);
    var pathData = Helper.GetBasePathData();
    CollegeGalleryService.List(
      {
        college_id: this.state.model.college_id,
        page: pathData.page,
        per_page: pathData.per_page,
      },
      (res: BaseResponse<any>) => {
        this.ShowDataLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.state.model.pagination = res.data;
        this.UpdateViewModel();
      }
    );
  }
  Create = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new CollegeGalleryModel());
    this.ShowDataWithFileSubmitLoader(true);
    CollegeGalleryService.Create(
      model,
      this.SetConfig,
      (res: BaseResponse<any>) => {
        this.ShowDataWithFileSubmitLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.ShowToast(res.message, "success");
        this.InitializeForm();
      }
    );
  };
  Upload = (event?: any) => {
    event.preventDefault();
    let fd = new FormData();
    let model = this.state.model;
    let files = model.files ?? [];
    fd.append("college_id", model.college_id?.toString() ?? "");
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      fd.append(`files[${index}]`, element);
    }
    this.ShowDataWithFileSubmitLoader(true);
    CollegeGalleryService.Upload(
      fd,
      this.SetConfig,
      (res: BaseResponse<any>) => {
        this.ShowDataWithFileSubmitLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.ShowToast(res.message, "success");
        this.InitializeForm();
      }
    );
  };
  SetFiles = (event: any) => {
    this.state.model.files = event.target.files;
    this.UpdateViewModel();
  };
  Delete = (id: number) => {
    if (!window.confirm("Are you sure delete this item ?")) {
      return false;
    }
    CollegeGalleryService.Delete({ id: id }, (res: BaseResponse<any>) => {
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.InitializeForm();
    });
  };
  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <div className="row mt-1">
          <form
            className="formValidate0"
            autoComplete="off"
            onSubmit={this.Upload}
          >
            <div
              className="row"
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#eee",
              }}
            >
              <div className="file-field input-field col s12">
                <div className="btn">
                  <span>Choose File</span>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.SetFiles}
                    multiple
                  />
                </div>
                <div className="file-path-wrapper">
                  <input
                    required
                    className="file-path validate valid"
                    type="text"
                  />
                </div>
              </div>
              <div className="input-field col s12">
                <button
                  className="btn waves-effect waves-light right bg-sky-500"
                  type="submit"
                  name="action"
                >
                  Upload
                  <i className="material-icons right">cloud</i>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div id="view-input-fields">
          <div className="row">
            <div className="col s12">
              {!model.is_data_loading && (
                <div className="row">
                  {model.pagination.data.map((item, index) => {
                    return (
                      <div className="col s12 m6 l4" key={index}>
                        <div className="card sticky-action">
                          <div className="card-image waves-effect waves-block waves-light">
                            <CustomImage
                              className="activator"
                              src={item.image_path}
                              style={{ height: 250 }}
                              alt={item.image_path}
                            />
                          </div>
                          <div className="card-content">
                            <div
                              className="card-title activator red-text text-darken-4"
                              onClick={() => this.Delete(item.id)}
                            >
                              Delete this item
                              <i className="material-icons right">delete</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {model.is_data_loading && <GridLoader />}
              {model.pagination.links.length > 3 && (
                <Pagination
                  data={model.pagination}
                  onChangeUpdate={this.OnChangeUpdate}
                  body={{ college_id: model.college_id }}
                />
              )}
            </div>
          </div>
        </div>
        <MasterControl data={model} />
      </>
    );
  }
}
