export default class PagePath {
    path: string = "";
    component?: any;
    name: string;
    icon?: string;
    is_selected: boolean = false;

    constructor(path: string, component: any, name?: string, icon?: string) {
        this.path = path;
        this.component = component;
        this.name = name ?? "";
        this.icon = icon;
    }
}