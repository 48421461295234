import BaseViewModel from '../../Core/BaseViewModel';
import { CollegeCategoryData } from '../../Models/CollegeCategoryModel';
import DesignationModel from '../../Models/DesignationModel';
import EmployeeAdditionalDesignationModel from '../../Models/EmployeeAdditionalDesignationModel';
import EmployeeModel from '../../Models/EmployeeModel';
import PaginationModel from '../../Models/PaginationModel';
export default class CategoryChildViewModel extends BaseViewModel {
  college_id?: number;
  facility_id?: number;
  data: CollegeCategoryData[] = [];
}
