import Axios, { AxiosError, AxiosResponse } from "axios";
import { BaseResponse, ErrorMessage, ErrorType } from "./BaseResponse";
import BaseCore from "./BaseCore";
import Helper from "./Helper";
import SessionHelper from "./SessionHelper";
export default class BaseApi {

  protected static readonly API_PATH: string = Helper.api_url;
  protected static readonly SUPER_ADMIN_PATH: string = this.API_PATH + "/super_admin";
  protected static readonly ADMIN_PATH: string = this.API_PATH + "/admin";
  protected static readonly USER_PATH: string = this.API_PATH + "/user";

  public static SetOption(config: any): any {
    const token = SessionHelper.GetToken();
    var headers = { headers: { "Authorization": `Bearer ${token}` } };
    var new_config = config ?? {};
    return Object.assign(new_config, headers);
  }

  protected static PostRequest(url: string, model: any, headers: any, callBack: any) {

    Axios.post(url, model, this.SetOption(headers)).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  protected static GetRequest(url: string, model: any, headers: any, callBack: any) {
    if (model) {
      url = url + BaseCore.ModelConvertToUrl(model);
    }

    Axios.get(url, this.SetOption(headers)).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  protected static PutRequest(url: string, model: any, headers: any, callBack: any) {

    Axios.put(url, model, this.SetOption(headers)).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  protected static DeleteRequest(url: string, model: any, headers: any, callBack: any) {

    if (model) {
      url = url + BaseCore.ModelConvertToUrl(model);
    }

    Axios.delete(url, this.SetOption(headers)).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  protected static FileRequest(url: string, model: any, headers: any, callBack: any) {

    var formData = BaseCore.ModelToFormData(model);

    Axios.post(url, formData, this.SetOption(headers)).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  protected static FormDataRequest(url: string, model: FormData, headers: any, callBack: any) {
    Axios.post(url, model, this.SetOption(headers)).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }


  private static ProcessSucess(res: AxiosResponse, callBack: any) {
    let r = res.data as BaseResponse<any>;
    callBack(r);
  }

  private static ProcessFailure(error: AxiosError, callBack: any) {
    //console.log(error);
    let response = new BaseResponse({ data: undefined, is_success: false, errors: [], message: error.message });
    var errorData = JSON.stringify(error);
    var obj = new ErrorMessage({ addition_info: errorData, error_type: ErrorType.error, message: error.message });
    response.errors.push(obj);
    response.message = error.message;
    callBack(response);
  }




}