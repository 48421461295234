import { createBrowserHistory } from 'history';
import { ReactNode } from 'react';
import MasterControl from '../../../Components/MasterControl';
import { TextCreateUpdate, withParams } from '../../../Components/PageHelper';
import BaseComponent from '../../../Core/BaseComponent';
import { BaseResponse } from '../../../Core/BaseResponse';

import BaseState from '../../../Core/BaseState';
import CollegeService from '../../../DataServices/Super/CollegeService';

import CollegeCreateUpdateViewModel, { UITab } from '../../../ViewModels/College/CollegeCreateUpdateViewModel';
import CollegeCategoryChildPage from './CollegeCategoryChildPage';
import CollegeCourseChildPage from './CollegeCourseChildPage';
import CollegeCreateUpdateChildPage from './CollegeCreateUpdateChildPage';
import CollegeDepartmentChildPage from './CollegeDepartmentChildPage';
import CollegeFacilityChildPage from './CollegeFacilityChildPage';
import CollegeGalleryChildPage from './CollegeGalleryChildPage';
import EmployeeChildPage from './EmployeeChildPage';

class CollegeCreateUpdatePage extends BaseComponent<any, CollegeCreateUpdateViewModel> {
  constructor(props: any) {
    super(props);
    this.state = new BaseState<CollegeCreateUpdateViewModel>(new CollegeCreateUpdateViewModel());
  }

  componentDidMount() {
    if (!this.isLoadComponent) {
      const id = this.props?.params?.id;
      if (id) {
        this.Details(id);
      }
      let model = this.state.model;
      model.id = id;
      model.profile_data.id = id;
      model.course_data.college_id = id;
      model.department_data.college_id = id;
      model.gallery_data.college_id = id;
      model.faculty_data.college_id = id;
      model.facility_data.college_id = id;
      model.category_data.college_id = id;
      this.UpdateViewModel();
      this.ChangeTab(UITab.profile);
    }
  }

  Details(id: number) {
    this.ShowDataLoader(true);
    CollegeService.Details({ id: id }, (res: BaseResponse<any>) => {
      this.ShowDataLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, 'danger');
        this.GoBack();
        return;
      }
      this.state.model.name = res.data.name;
      this.UpdateViewModel();
    });
  }

  SetCollegeId = (data: any) => {
    let model = this.state.model;
    const college_id = data.college_id;
    model.profile_data.id = college_id;
    model.course_data.college_id = college_id;
    model.department_data.college_id = college_id;
    model.gallery_data.college_id = college_id;
    model.faculty_data.college_id = college_id;
    model.facility_data.college_id = college_id;
    model.category_data.college_id = college_id;
    this.ChangeTab(data.tab);
    this.UpdateViewModel();
  };

  ChangeTab = (tab: UITab) => {
    let model = this.state.model;
    let tabs = model.tabs;
    for (let index = 0; index < tabs.length; index++) {
      const element = tabs[index];
      element.is_active = false;
      if (element.tab === tab) {
        element.is_active = true;
      }
    }

    let layout = null;
    switch (tab) {
      case UITab.profile:
        layout = <CollegeCreateUpdateChildPage data={model.profile_data} SetCollegeId={this.SetCollegeId} />;
        break;
      case UITab.course:
        layout = <CollegeCourseChildPage data={model.course_data} />;
        break;
      case UITab.department:
        layout = <CollegeDepartmentChildPage data={model.department_data} />;
        break;
      case UITab.gallery:
        layout = <CollegeGalleryChildPage data={model.gallery_data} />;
        break;
      case UITab.faculty:
        layout = <EmployeeChildPage data={model.faculty_data} />;
        break;
      case UITab.facility:
        layout = <CollegeFacilityChildPage data={model.faculty_data} />;
        break;
      case UITab.category:
        layout = <CollegeCategoryChildPage data={model.category_data} />;
        break;
      default:
        layout = <CollegeCreateUpdateChildPage data={model.profile_data} SetCollegeId={this.SetCollegeId} />;
        break;
    }
    let history = createBrowserHistory();
    history.push(`?tab=${tab}`);
    model.tabs = tabs;
    model.tab_layout = layout;
    this.UpdateViewModel();
  };

  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <div className="row">
          <div
            id="breadcrumbs-wrapper"
            data-image={`${process.env.PUBLIC_URL}/assets/images/gallery/breadcrumb-bg.jpg`}
          >
            <div className="container">
              <div className="row">
                <div className="col s12 m6 l6">
                  <h5 className="breadcrumbs-title mt-0 mb-0">
                    <span>College</span>
                  </h5>
                </div>
                <div className="col s12 m6 l6 right-align-md">
                  <ol className="breadcrumbs mb-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Master</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#!">College</a>
                    </li>
                    <li className="breadcrumb-item active">{TextCreateUpdate(model.id)}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div id="input-fields" className="card card-tabs">
                <div className="card-content">
                  <div className="card-title">
                    <div className="row">
                      <div className="col s12 m6 l6">
                        <h5 className="card-title">{model.id ? model.name : 'College Create'}</h5>
                      </div>
                      <div className="col s12 m6 l6">
                        <button className="btn right" onClick={this.GoBack}>
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="view-input-fields">
                    <div className="row">
                      <ul className="tabs">
                        {model.tabs.map((item, index) => {
                          const bgColor = item.is_active ? '#4287f5' : '#d8e1f0';
                          const txtColor = item.is_active ? 'white' : 'black';
                          return (
                            <li onClick={() => this.ChangeTab(item.tab)} key={index} className="tab col l2  s12">
                              <div
                                style={{
                                  background: bgColor,
                                  cursor: 'pointer',
                                  textAlign: 'center',
                                  color: txtColor,
                                  paddingLeft: '12px',
                                  paddingRight: '12px',

                                  lineHeight: '33px',

                                  borderRadius: 5,
                                  width: 'full,',
                                }}
                              >
                                {item.label}
                              </div>
                            </li>

                            //   <div className="col tab s12 l3 " key={index} onClick={() => this.ChangeTab(item.tab)} style={{ background: bgColor, cursor: "pointer", textAlign: "center", color: txtColor, padding: 5, margin: 0.5, borderRadius: 5, width: "full,", }} > {item.label} </div>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="row">
                      <div className="col s12">{model.tab_layout}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MasterControl data={model} />
      </>
    );
  }
}

export default withParams(CollegeCreateUpdatePage);
