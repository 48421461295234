import BaseModel from "../Core/BaseModel";

export default class DesignationModel extends BaseModel {
    name?: string;
    slug?:string;
    abbreviation?:string;
    image_path?: string;

    //Request
    file?:File;
}