interface IBaseResponse<M> {
    is_success: boolean;
    data?: M;
    errors: ErrorMessage[];
    message: string;
}
export enum ErrorType {
    error,
    warning,
    info,
    success
}

export interface IErrormessage {
    field_name?: string;
    message: string;
    addition_info?: any | null;
    error_type: ErrorType;
}

export class BaseResponse<M> {
    is_success: boolean;
    data: M;
    errors: ErrorMessage[];
    message: string;

    constructor(data: IBaseResponse<M> = { is_success: false, data: {} as M, errors: [], message: "" }) {
        this.is_success = data.is_success;
        this.data = data.data as M;
        this.errors = data.errors || [];
        this.message = data.message;
    }
}

export class ErrorMessage {
    key: string;
    field_name?: string;
    message: string;
    addition_info?: any | null;
    error_type: ErrorType;

    constructor(data: IErrormessage = { addition_info: null, field_name: undefined, message: "", error_type: ErrorType.error }) {
        this.key = Math.random().toString(36).substr(2, 5);
        this.field_name = data.field_name;
        this.message = data.message;
        this.addition_info = data.addition_info;
        this.error_type = data.error_type;
    }
}

