import BaseApi from "../../Core/BaseApi";

export default class CollegeDepartmentService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/college_department/list`, model, null, callBack);
    }

    public static InitializeForm(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/college_department/initialize_form`, model, null, callBack);
    }

    public static Create(model: any, callBack: any) {
        this.PostRequest(`${this.SUPER_ADMIN_PATH}/college_department/create`, model, null, callBack);
    }

    public static Update(model: any, callBack: any) {
        this.PutRequest(`${this.SUPER_ADMIN_PATH}/college_department/update`, model, null, callBack);
    }

    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/college_department/details`, model, null, callBack);
    }

    public static Delete(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/college_department/delete`, model, null, callBack);
    }
}