import BaseApi from "../../Core/BaseApi";

export default class CityService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/city/list`, model, null, callBack);
    }

    public static InitializeForm(callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/city/initialize_form`, null, null, callBack);
    }

    public static Create(model: any, headers: any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/city/create`, model, headers, callBack);
    }

    public static Update(model: any, headers: any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/city/update`, model, headers, callBack);
    }

    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/city/details`, model, null, callBack);
    }

    public static Delete(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/city/delete`, model, null, callBack);
    }
}