import { ErrorMessage } from './BaseResponse';

export default class BaseViewModel {
  id?: number;
  progress: number = 0;
  current_page: number = 1;
  per_page: number = 3;
  is_modal_open: boolean = false;
  is_preview_modal_open: boolean = false;
  modal_data?: Modal;
  is_data_loading: boolean = false;
  is_data_submit: boolean = false;
  is_data_with_file_submit: boolean = false;

  messages: ErrorMessage[] = [];

  public OnToastEntered = (e: any, reason: any, key: string) => {
    this.messages = this.messages.filter((i) => i.key !== key);
  };
}

export class Modal {
  type?: any;
  src?: string;
  poster?: string;
  title?: string;
}

export enum ModalType {
  image,
  video,
  audio,
  pdf,
  other,
}
