import BaseViewModel from "../../Core/BaseViewModel";
import CountryModel from "../../Models/CountryModel";
import StateModel from "../../Models/StateModel";

export default class StateCreateUpdateViewModel extends BaseViewModel {
    country_id?: number;
    state_id?: number;
    name?: string;
    abbreviation?: string;
    image_path?: string;

    countries: CountryModel[] = [];
    all_states: StateModel[] = [];
    states: StateModel[] = [];
    
    

    //Request
    file?: File;
}