import BaseModel from "../Core/BaseModel";
import CourseModel from "./CourseModel";

export default class DepartmentModel extends BaseModel {
    slug?: string;
    course_id?: number;
    category_id?: number;
    name?: string;
    image_path?: string;
    course?: CourseModel;

    //Requset
    file?: File;
}