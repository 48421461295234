import BaseViewModel from "../../Core/BaseViewModel";
import CourseModel from "../../Models/CourseModel";

export default class DepartmentCreateUpdateViewModel extends BaseViewModel {
    slug?: string;
    course_id?: number;
    name?: string;
    image_path?: string;

    courses: CourseModel[] = [];

    //Request
    file?: File;
}