import BaseModel from "../Core/BaseModel";
import CityModel from "./CityModel";
import CollegeProfileModel from "./CollegeProfileModel";
import CountryModel from "./CountryModel";
import DistrictModel from "./DistrictModel";
import StateModel from "./StateModel";
import UniversityModel from "./UniversityModel";

export default class CollegeModel extends BaseModel {
    slug?: string;
    college_profile_id?: number;
    name?: string;
    abbreviation?: string;
    affiliations_university_id?: number;
    approved_by: any[] = [];
    type?: any;
    nirf_rank?: number=0;
    is_naac?: boolean;
    naac_grade?: string;
    
    contact_no?: string;
    alternative_contact_no?: string;
    email?: string;
    alternative_email?: string;
    country_id?: number;
    district_id?: number;
    state_id?: number;
    city_id?: number;
    address?: number;
    logo_path?: string;
    est_date?: Date;
    est_year?: number;

    status?: any;
    

    college_profile?: CollegeProfileModel;
    affiliations_university?: UniversityModel;
    country?: CountryModel;
    state?: StateModel;
    district?: DistrictModel;
    city?: CityModel;

    //Requset
    logo_file?: File;
    cover_image?:File;
}