import { ReactNode } from "react";
import BaseComponent from "../../../Core/BaseComponent";
import { BaseResponse } from "../../../Core/BaseResponse";
import BaseState from "../../../Core/BaseState";
import UserService from "../../../DataServices/Super/UserService";
import UserModel, { UserRegisterOrigin, UserRole, UserStatus } from "../../../Models/UserModel";
import PaginationModel from "../../../Models/PaginationModel";
import UserListViewModel from "../../../ViewModels/User/UserListViewModel";
import Helper from "../../../Core/Helper";
import Pagination from "../../../Components/Pagination";
import { Link } from "react-router-dom";
import Constant from "../../../Core/Constant";
import MasterControl from "../../../Components/MasterControl";
import ListLoader from "../../../Components/ListLoader";

export default class UserListPage extends BaseComponent<any, UserListViewModel> {

    constructor(props: any) {
        super(props);
        this.state = new BaseState<UserListViewModel>(new UserListViewModel());

    }

    componentDidMount(): void {

        if (!this.isLoadComponent) {
            this.isLoadComponent = true;
            this.GetList();
        }
    }

    GetList() {
        var pathData = Helper.GetBasePathData();
        this.ShowDataLoader(true);
        UserService.List({ page: pathData.page, per_page: pathData.per_page }, (res: BaseResponse<any>) => {
            this.ShowDataLoader(false);
            if (!res.is_success) {
                return this.ShowToast(res.message, "danger");
            }

            this.state.model.pagination = res.data as PaginationModel<UserModel>;
            this.UpdateViewModel();
        })
    }

    // Delete = (id: number) => {

    //     if (!window.confirm("Are you sure delete this item ?")) {
    //         return false;
    //     }
    //     UserService.Delete({ id: id }, (res: BaseResponse) => {
    //         if (!res.is_success) {
    //             this.ShowToast(res.message, "danger");
    //             return;
    //         }
    //         this.ShowToast(res.message, "success");
    //         this.Reload();
    //     });
    // }







    render(): ReactNode {
        var model = this.state.model;

        return (
            <>
                <div className="row">
                    <div id="breadcrumbs-wrapper" data-image={`${process.env.PUBLIC_URL}/assets/images/gallery/breadcrumb-bg.jpg`}>
                        <div className="container">
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <h5 className="breadcrumbs-title mt-0 mb-0"><span>User</span></h5>
                                </div>
                                <div className="col s12 m6 l6 right-align-md">
                                    <ol className="breadcrumbs mb-0">
                                        <li className="breadcrumb-item"><a href="#!">Master</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="#!">User</a>
                                        </li>
                                        <li className="breadcrumb-item active">List
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div id="input-fields" className="card card-tabs">
                                <div className="card-content">
                                    <div className="card-title">
                                        <div className="row">
                                            <div className="col s12 m6 l6">
                                                <h4 className="card-title">User List</h4>
                                            </div>
                                            <div className="col s12 m6 l6">
                                                <Link to={`${Constant.SUPER_ADMIN_PATH}/user/create`} className="btn right">Add User</Link>
                                            </div>

                                        </div>
                                    </div>
                                    <div id="view-input-fields">
                                        <div className="row">
                                            <div className="col s12">

                                                {!model.is_data_loading &&
                                                    <table className="bordered highlight">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Contact</th>
                                                                <th>Email</th>
                                                                <th>Role</th>
                                                                <th>Origin</th>
                                                                <th>Registered</th>
                                                                <th>Status</th>
                                                                <td>Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {model.pagination.data.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.contact_no}</td>
                                                                        <td>{item.email}</td>
                                                                        <td>{UserRole[item.role]}</td>
                                                                        <td>{UserRegisterOrigin[item.register_origin]}</td>
                                                                        <td>{Helper.ToDateFormat(item.created_at)}</td>
                                                                        <td>
                                                                            {item.status === UserStatus.active && <span style={{ color: 'green' }}>Active</span>}
                                                                            {item.status === UserStatus.inactive && <span style={{ color: 'red' }}>Inactive</span>}
                                                                        </td>

                                                                        <td>
                                                                            <Link to={`${Constant.SUPER_ADMIN_PATH}/user/update/${item.id}`} className="material-icons">edit</Link>

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                }
                                                {model.is_data_loading && <ListLoader />}

                                                {model.pagination.links.length > 0 &&
                                                    <Pagination data={model.pagination} onChangeUpdate={this.OnChangeUpdate} />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <MasterControl data={model} />

            </>
        )
    }
}