import { ReactNode } from 'react';
import CustomImage from '../../../Components/CustomImage';
import MasterControl from '../../../Components/MasterControl';
import BaseComponent from '../../../Core/BaseComponent';
import { BaseResponse } from '../../../Core/BaseResponse';
import BaseState from '../../../Core/BaseState';
import CollegeFacilityService from '../../../DataServices/Super/CollegeFacilityService';
import CollegeFacilityChildViewModel from '../../../ViewModels/College/CollegeFacilityChildViewModel';
export default class CollegeFacilityChildPage extends BaseComponent<any, CollegeFacilityChildViewModel> {
  constructor(props: any) {
    super(props);
    const { data } = props;
    this.state = new BaseState<CollegeFacilityChildViewModel>(data);
  }
  componentDidMount() {
    this.GetFacilities();
  }
  GetFacilities() {
    this.ShowDataLoader(true);
    CollegeFacilityService.Facilities({ college_id: this.state.model.college_id }, (res: BaseResponse<any>) => {
      console.log(res);
      this.ShowDataLoader(false);
      // debugger
      if (!res.is_success) {
        this.ShowToast(res.message, 'danger');
        return;
      }
      this.state.model.data = res.data;
      this.UpdateViewModel();
    });
  }
  Check = (facility_id?: number) => {
    let model = {
      facility_id: facility_id,
      college_id: this.state.model.college_id,
    };
    this.ShowDataSubmitLoader(true);
    CollegeFacilityService.Check(model, (res: BaseResponse<any>) => {
      this.ShowDataSubmitLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, 'danger');
        return;
      }
      this.ShowToast(res.message, 'success');
      this.GetFacilities();
    });
  };
  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <div className="row mt-1">
          {model.data?.map((data, index) => {
            return (
              <>
                <h5 className="text-xl text-black font-extrabold my-3">{data.name}</h5>
                <div className="grid grid-cols-3 " key={index}>
                  {data.facilities?.map((facility, index2) => {
                    return (
                      // <div className=" m-4" key={index2}>
                      //   {/* <img src={facility.image_path} alt="" />
                      //     {/* -- */}
                      //   <div className="w-full max-w-md  bg-white border border-gray-200 rounded-lg shadow sm:p-4 dark:bg-gray-800 dark:border-gray-700">
                      //     <div className="flow-root">
                      <div key={index2} className="divide-y divide-gray-200 dark:divide-gray-700 m-2">
                        <div className="">
                          <div className="flex items-center space-x-4 mt-4">
                            <div className="flex-1  min-w-0">
                              <div className="text-sm flex  font-medium text-gray-900 truncate dark:text-white">
                                <div className="flex-shrink-0 justify-center">
                                  <CustomImage
                                    className="w-14 h-14 mx-auto rounded-full"
                                    src={facility.image_path}
                                    alt={facility.name}
                                  />
                                </div>
                                <label className="ml-5 my-auto">
                                  <input
                                    id="facility"
                                    name="facility"
                                    type="checkbox"
                                    checked={facility.is_active}
                                    onClick={() => this.Check(facility.id)}
                                  />
                                  <span>{facility.name}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      //     </div>
                      //   </div>
                      //   {/* -- */}
                      // </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
        <MasterControl data={model} />
      </>
    );
  }
}
