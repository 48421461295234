import BaseModel from "../Core/BaseModel";
import DesignationModel from "./DesignationModel";
import EmployeeAdditionalDesignationModel from "./EmployeeAdditionalDesignationModel";

export default class EmployeeModel extends BaseModel {
    college_id?: number;
    designation_id?: number;
    name?: string;
    contact?: string;
    email?: string;
    qualifications?: string;
    experience_year?: number;
    experience_month?: number;
    profile_image_path?: string;
    status?: any;

    designation?: DesignationModel;
    additional_designations: EmployeeAdditionalDesignationModel[] = [];

    //Request
    file?: File;
}

export enum CollegeStatus {
    pending
}