import BaseViewModel from "../../Core/BaseViewModel";
import CollegeCourseModel from "../../Models/CollegeCourseModel";
import CourseModel from "../../Models/CourseModel";


export default class CollegeCourseChildViewModel extends BaseViewModel {

    college_id?: number;
    course_id?: number;
    seats?: number;
    total_fees_amount?: number;
    average_fees_amount?: number;
    admission_fees_amount?: number;
    semester_fees_amount?: number;
    fees_description?: string;
    


    //Bind
    courses:CourseModel[]=[];
    college_courses: CollegeCourseModel[] = [];
    
}
