import PropTypes, { InferProps } from "prop-types";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'


class GridLoaderViewModel {
    count: number = 10;

}

const ControlPropType = {
    data: PropTypes.instanceOf(GridLoaderViewModel)
};

export default function ListLoader(props: InferProps<typeof ControlPropType>) {
    const { data } = props;
    let count = data?.count ?? 10;
    let items = [];
    for (let index = 1; index <= count; index++) {
        items.push(index);
    }
    return (
        <>
            <table className="bordered highlight">
                <thead>
                    <tr>
                        <th><Skeleton count={1} /></th>
                    </tr>
                </thead>
                <tbody>

                    {items.map((item) => {
                        return (
                            <tr key={item}>
                                <td><Skeleton count={1} />
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
        </>
    );
}

ListLoader.defaultProps = ControlPropType;

