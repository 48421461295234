import BaseViewModel from "../../Core/BaseViewModel";
import DesignationModel from "../../Models/DesignationModel";
import EmployeeAdditionalDesignationModel from "../../Models/EmployeeAdditionalDesignationModel";
import EmployeeModel from "../../Models/EmployeeModel";
import PaginationModel from "../../Models/PaginationModel";
export default class EmployeeChildViewModel extends BaseViewModel {
  college_id?: number;
  designation_id?: number;
  name?: string;
  contact?: string;
  email?: string;
  qualifications?: string;
  experience_year?: number;
  experience_month?: number;
  profile_image_path?: any;
  status?: any;
  additional_designations: EmployeeAdditionalDesignationModel[] = [];
  file?: any;

  is_image_preview:boolean=false;
  current_url?: string;
  current_alt?: string;
  // profile_image_temp_path: any;
  //Bind
  pagination: PaginationModel<EmployeeModel> =
    new PaginationModel<EmployeeModel>();
  designations: DesignationModel[] = [];
}
