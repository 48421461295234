import BaseApi from "../../Core/BaseApi";

export default class DepartmentService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/department/list`, model,null, callBack);
    }


    public static Create(model: any,headers:any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/department/create`, model,headers, callBack);
    }

    public static Update(model: any,headers:any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/department/update`, model,headers, callBack);
    }

    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/department/details`, model,null, callBack);
    }

    public static Delete(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/department/delete`, model,null, callBack);
    }
}