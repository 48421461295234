import moment from 'moment';
import { Navigate } from 'react-router-dom';
import { UserRole } from '../Models/UserModel';
import Constant from './Constant';
export enum SoftwareMode {
  test,
  production,
  local,
}

export default class Helper {
  public static software_mode: SoftwareMode = SoftwareMode.test;
  public static logo: string = '';

  public static get api_url(): string {
    var url = null;
    switch (this.software_mode) {
      case SoftwareMode.test:
        url = 'https://college.princeglobe.in/webapp/public/api';
        break;
      case SoftwareMode.production:
        url = '';
        break;
      case SoftwareMode.local:
        url = 'http://127.0.0.1:8000/api';
        break;
      default:
        break;
    }
    return url as string;
  }

  public static GetName(first?: string, middle?: string, last?: string) {
    let name = '';
    if (first) {
      name += first;
    }
    if (middle) {
      name = name + ' ' + middle;
    }
    if (last) {
      name = name + ' ' + last;
    }
    return name;
  }

  public static FileOpen() {
    var url = null;
    switch (this.software_mode) {
      case SoftwareMode.test:
        url = '';
        break;
      case SoftwareMode.production:
        url = '';
        break;
      case SoftwareMode.local:
        url = '';
        break;
      default:
        break;
    }
    return url;
  }

  public static OnChangeReactSelect(value: any, e: any) {
    return Helper.OnChange(e.name, value.value);
  }

  public static OnChangeDropZone(e: any, s: string, value: any[], name: string) {
    var actualFiles = value.map((i) => i.file);
    if (s === 'removed') {
      actualFiles = value
        .filter((i) => {
          return i.file.name !== e.file.name;
        })
        .map((i) => i.file);
    }
    return Helper.OnChange(name, actualFiles);
  }

  public static OnChange(name: string, value: any) {
    var newE = { target: { name: name, value: value, id: name } };
    return newE;
  }

  public static AppendLinkChild(value: string): void {
    var links = document.getElementsByTagName('link') as unknown as HTMLLinkElement[];
    let exists = false;
    for (let index = 0; index < links.length; index++) {
      const element = links[index];
      var href = element.href;
      let domain = new URL(href).hostname;
      if (domain === window.location.hostname) {
        const lastIndex = element.href.lastIndexOf('/') + 1;
        href = href.substring(lastIndex);
      }

      if (href === value) exists = true;
    }
    if (!exists) {
      var head = document.head;
      var link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = value;
      head.appendChild(link);
    }
  }

  public static AppendScriptChild(value: string): void {
    var scripts = document.getElementsByTagName('script') as unknown as any[];
    let exists = false;
    for (let index = 0; index < scripts.length; index++) {
      const element = scripts[index];
      if (element.attributes.src?.nodeValue === value) exists = true;
    }
    if (!exists) {
      var head = document.head;
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = value;
      head.appendChild(script);
    }
  }

  public static SnakeToTitleCase(value: string) {
    var parts = value.split('_');
    var str = '';

    for (let index = 0; index < parts.length; index++) {
      var element = parts[index];
      var first_char = element.charAt(0).toUpperCase();
      if (index === 0) {
        str += first_char + element.substring(1);
      } else {
        str += ' ' + first_char + element.substring(1);
      }
    }

    return str;
  }

  public static NavigateRedirect(role: UserRole) {
    let path = '';
    switch (role) {
      case UserRole.super:
        path = Constant.SUPER_ADMIN_PATH;
        break;
      case UserRole.admin:
        path = Constant.ADMIN_PATH;
        break;
      case UserRole.user:
        path = Constant.USER_PATH;
        break;
      case UserRole.student:
        path = Constant.STUDENT_PATH;
        break;
    }
    return <Navigate to={path} />;
  }

  public static GetBasePathData(url?: string) {
    if (!url) {
      url = window.location.href;
    }
    var url_obj = new URL(url);
    var pageString = url_obj.searchParams.get('page') ?? '';
    var page = parseInt(pageString);
    var perPageString = url_obj.searchParams.get('per_page') ?? '';
    var perPage = parseInt(perPageString);
    var data = new BasePathDataModel();
    if (isNaN(page) || !page) {
      page = data.page;
    }

    if (isNaN(perPage) || !perPage) {
      perPage = data.per_page;
    }

    data.page = page;
    data.per_page = perPage;
    return data;
  }

  public static ToDateFormat(date: any, format: string = 'D MMM YYYY') {
    return moment(date).format(format);
  }

  public static ArrayAddRemove(array: any[], value: any) {
    var index = array.indexOf(value);
    if (index < 0) {
      array.push(value);
    } else {
      array.splice(index, 1);
    }
    return array;
  }
}

class BasePathDataModel {
  page: number = 1;
  per_page: number = 10;
}
