import { ReactNode } from "react";
import ListLoader from "../../../Components/ListLoader";
import MasterControl from "../../../Components/MasterControl";
import BaseComponent from "../../../Core/BaseComponent";
import BaseCore from "../../../Core/BaseCore";
import { BaseResponse } from "../../../Core/BaseResponse";
import BaseState from "../../../Core/BaseState";
import CollegeCourseService from "../../../DataServices/Super/CollegeCourseService";
import CollegeCourseModel from "../../../Models/CollegeCourseModel";
import CollegeCourseChildViewModel from "../../../ViewModels/College/CollegeCourseChildViewModel";
export default class CollegeCourseChildPage extends BaseComponent<
  any,
  CollegeCourseChildViewModel
> {
  constructor(props: any) {
    super(props);
    const { data } = props;
    this.state = new BaseState<CollegeCourseChildViewModel>(data);
  }
  componentDidMount() {
    this.InitializeForm();
  }
  InitializeForm() {
    this.ShowDataLoader(true);
    CollegeCourseService.InitializeForm(
      { college_id: this.state.model.college_id },
      (res: BaseResponse<any>) => {
        this.ShowDataLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.state.model.courses = res.data.courses;
        this.state.model.college_courses = res.data.college_courses;
        this.UpdateViewModel();
      }
    );
  }
  GetCollegeCourses() {
    this.ShowDataLoader(true);
    CollegeCourseService.List(
      { college_id: this.state.model.college_id },
      (res: BaseResponse<Array<any>>) => {
        this.ShowDataLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.state.model.college_courses = res.data;
        this.UpdateViewModel();
      }
    );
  }
  ToggleModal = (data: CollegeCourseModel | any = null) => {
    let model = this.state.model;
    model.is_modal_open = this.state.model.is_modal_open ? false : true;
    if (data) {
      model.id = data.id;
      model.course_id = data.course_id;
      model.seats = data.seats;
      model.total_fees_amount = data.total_fees_amount;
      model.average_fees_amount = data.average_fees_amount;
      model.admission_fees_amount = data.admission_fees_amount;
      model.semester_fees_amount = data.semester_fees_amount;
      model.fees_description = data.fees_description;
    } else {
      model.id = null as any;
      model.course_id = null as any;
      model.seats = null as any;
      model.total_fees_amount = null as any;
      model.average_fees_amount = null as any;
      model.admission_fees_amount = null as any;
      model.semester_fees_amount = null as any;
      model.fees_description = null as any;
    }
    this.UpdateViewModel();
  };
  Create = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new CollegeCourseModel());
    this.ShowDataSubmitLoader(true);
    CollegeCourseService.Create(model, (res: BaseResponse<any>) => {
      this.ShowDataSubmitLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.GetCollegeCourses();
      this.ToggleModal();
    });
  };
  Update = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new CollegeCourseModel());
    if (!window.confirm("Are you sure ?")) {
      return false;
    }
    this.ShowDataSubmitLoader(true);
    CollegeCourseService.Update(model, (res: BaseResponse<any>) => {
      this.ShowDataSubmitLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.GetCollegeCourses();
      this.ToggleModal();
    });
  };
  Delete = (id: number) => {
    if (!window.confirm("Are you sure delete this item ?")) {
      return false;
    }
    CollegeCourseService.Delete({ id: id }, (res: BaseResponse<any>) => {
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.GetCollegeCourses();
    });
  };
  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <div className="row mt-1">
          <button
            className="btn right btn right mx-4 mb-4"
            onClick={() => this.ToggleModal(null)}
          >
            Add
          </button>
          <div>
            {!model.is_data_loading && (
              <table className="bordered highlight">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Seats</th>
                    <th>Admission fees</th>
                    <th>Total fees</th>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {model.college_courses.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.course?.name}</td>
                        <td>{item.seats}</td>
                        <td>{item.admission_fees_amount}</td>
                        <td>{item.total_fees_amount}</td>
                        <td>
                          <i
                            className="material-icons green-text"
                            onClick={() => this.ToggleModal(item)}
                          >
                            edit
                          </i>
                          <i
                            className="material-icons red-text"
                            onClick={() => this.Delete(item.id)}
                          >
                            delete
                          </i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {model.is_data_loading && <ListLoader />}
            <MasterControl data={model} />
          </div>
        </div>
        {model.is_modal_open && (
          <div
            id="modal1"
            className="modal"
            style={{ display: "block", top: 90, zIndex: 1000 }}
          >
            <div className="modal-content">
              <form
                className="formValidate0"
                autoComplete="off"
                onSubmit={model.id ? this.Update : this.Create}
              >
                <h5 style={{ color: "green" }}>
                  {model.id ? "Update" : "Add"} Course
                </h5>
                <div
                  className="row"
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#eee",
                  }}
                >
                  <div
                    className="input-field  col s12 l6"
                    data-select2-id={111}
                  >
                    <select
                      name="course_id"
                      
                      value={model.course_id}
                      onChange={this.SetCustomeState}
                      className="select2 browser-default"
                      data-select2-id={1}
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <option value="" data-select2-id={3}>
                        ---Select Course ---
                      </option>
                      {model.courses.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="seats"
                      className={model.seats ? "active" : ""}
                    >
                      Seats
                    </label>
                    <input
                      id="seats"
                      name="seats"
                      value={model.seats}
                      onChange={this.SetCustomeState}
                      type="number"
                    />
                  </div>
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="total_fees_amount"
                      className={model.total_fees_amount ? "active" : ""}
                    >
                      Total fees amount
                    </label>
                    <input
                      id="total_fees_amount"
                      name="total_fees_amount"
                      value={model.total_fees_amount}
                      onChange={this.SetCustomeState}
                      type="number"
                    />
                  </div>
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="average_fees_amount"
                      className={model.average_fees_amount ? "active" : ""}
                    >
                      Average Fees Amount
                    </label>
                    <input
                      id="average_fees_amount"
                      name="average_fees_amount"
                      value={model.average_fees_amount}
                      onChange={this.SetCustomeState}
                      type="number"
                    />
                  </div>
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="admission_fees_amount"
                      className={model.admission_fees_amount ? "active" : ""}
                    >
                      Admission fees amount
                    </label>
                    <input
                      id="admission_fees_amount"
                      name="admission_fees_amount"
                      value={model.admission_fees_amount}
                      onChange={this.SetCustomeState}
                      type="number"
                    />
                  </div>
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="semester_fees_amount"
                      className={model.semester_fees_amount ? "active" : ""}
                    >
                      Semester fees amount
                    </label>
                    <input
                      id="semester_fees_amount"
                      name="semester_fees_amount"
                      value={model.semester_fees_amount}
                      onChange={this.SetCustomeState}
                      type="number"
                    />
                  </div>
                  <div className="input-field col s12 l12">
                    <label
                      htmlFor="fees_description"
                      className={model.fees_description && "active"}
                    >
                      Fees Description
                    </label>
                    <textarea
                      className="materialize-textarea"
  
                      id="fees_description"
                      name="fees_description"
                      value={model.fees_description}
                      onChange={this.SetCustomeState}
                    ></textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    style={{ backgroundColor: "green", color: "white" }}
                    className=" modal-action modal-close waves-effect waves-green btn-flat"
                  >
                    {model.id ? "Update" : "Add"}
                  </button>
                  <button
                    onClick={this.ToggleModal}
                    style={{
                      marginLeft: 5,
                      backgroundColor: "red",
                      color: "white",
                    }}
                    className=" modal-action modal-close waves-effect waves-green btn-flat"
                  >
                    Exit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    );
  }
}
