import BaseApi from "../../Core/BaseApi";

export default class DistrictService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/district/list`, model,null, callBack);
    }

    public static InitializeForm(callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/district/initialize_form`,null, null, callBack);
    }

    public static Create(model: any,headers:any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/district/create`, model,headers, callBack);
    }

    public static Update(model: any, headers:any,callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/district/update`, model,headers, callBack);
    }

    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/district/details`, model,null, callBack);
    }

    public static Delete(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/district/delete`, model,null, callBack);
    }
}