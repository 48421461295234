import BaseApi from "../../Core/BaseApi";

export default class CollegeGalleryService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/college_gallery/list`, model, null, callBack);
    }

    public static Upload(model: FormData, headers: any, callBack: any) {
        this.FormDataRequest(`${this.SUPER_ADMIN_PATH}/college_gallery/upload`, model, headers, callBack);
    }

    public static Create(model: any, headers: any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/college_gallery/create`, model, headers, callBack);
    }

    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/college_gallery/details`, model, null, callBack);
    }

    public static Delete(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/college_gallery/delete`, model, null, callBack);
    }
}