import BaseViewModel from "../../Core/BaseViewModel";
import CollegeGalleryModel from "../../Models/CollegeGalleryModel";
import PaginationModel from "../../Models/PaginationModel";


export default class CollegeGalleryChildViewModel extends BaseViewModel {

    college_id?: number;
    comment?: string;

    //Request    
    files?:any[]=[];
    //Bind
    pagination: PaginationModel<CollegeGalleryModel> = new PaginationModel<CollegeGalleryModel>();
    
}
