import BaseModel from '../Core/BaseModel';

export default class CategoryModel extends BaseModel {
  slug?: string;
  name?: string;
  image_path?: string;

  //Request
  file?: File;
  is_active: boolean | undefined;
}
