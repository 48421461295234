import BaseViewModel from "../../Core/BaseViewModel";
import EnumResponseModel from "../../Core/EnumResponseModel";
import CityModel from "../../Models/CityModel";
import CountryModel from "../../Models/CountryModel";
import DistrictModel from "../../Models/DistrictModel";
import StateModel from "../../Models/StateModel";
import UniversityModel from "../../Models/UniversityModel";




export default class CollegeCreateUpdateChildViewModel extends BaseViewModel {
    tabs: TabModel[] = tabs;
    slug?: string;
    college_profile_id?: number;
    name?: string;
    abbreviation?: string;
    affiliations_university_id?: number;
    approved_by: any[] = [];
    type?: string;
    nirf_rank?: number;
    is_naac?: boolean;
    naac_grade?: string;

    contact_no?: string;
    alternative_contact_no?: string;
    email?: string;
    alternative_email?: string;
    country_id?: number;
    district_id?: number;
    state_id?: number;
    city_id?: number;
    address?: string;
    logo_path?: string;
    est_date?: Date;
    est_year?: number;

    status?: any;

    //For bind
    universities: UniversityModel[] = [];
    countries: CountryModel[] = [];
    all_states: StateModel[] = [];
    states: StateModel[] = [];
    all_districts: DistrictModel[] = [];
    districts: DistrictModel[] = [];
    all_city: CityModel[] = [];
    cities: CityModel[] = [];
    approved_by_list: EnumResponseModel[] = [];
    types: EnumResponseModel[] = [];

    //Request
    logo_file?: File;
    cover_image?:File;
}


class TabModel {
    label: string = null as any;
    tab: UITab = null as any;
    is_active: boolean = false;
}

export enum UITab {
    profile,
    course,
    department,
    gallery,
    facility,
    faculty
}

const tabs: TabModel[] = [
    { label: "Profile", tab: UITab.profile, is_active: true },
    { label: "Course", tab: UITab.course, is_active: false },
    { label: "Department", tab: UITab.department, is_active: false },
    { label: "Gallery", tab: UITab.gallery, is_active: false },
    { label: "Facility", tab: UITab.facility, is_active: false },
    { label: "Faculty", tab: UITab.faculty, is_active: false }
];