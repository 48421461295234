import { useNavigate } from 'react-router-dom';
import './App.css';
import AppStart from './Pages/AppStart';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

toast.dismiss();
// toast.configure({
//   position: toast.POSITION.TOP_CENTER,
// });

function App(props: any) {
  let navigate = useNavigate();
  return (
    <>
      <AppStart {...props} navigate={navigate} />
      <ToastContainer />
    </>
  );
}

export default App;



