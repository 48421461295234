import BaseViewModel from "../../Core/BaseViewModel";
import EnumResponseModel from "../../Core/EnumResponseModel";

export default class UserCreateUpdateViewModel extends BaseViewModel {
    name?: string;
    email?: string;
    contact_no?: string;
    user_name?: string;
    role?: any;
    status?: any;
    api_token?: string;

    //Request
    password?: string;
    confirm_password?: string;

    //View
    roles: EnumResponseModel[] = [];
    statuses: EnumResponseModel[] = [];
}