import PropTypes, { InferProps } from "prop-types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
class GridLoaderViewModel {
  count: number = 10;
}
const ControlPropType = {
  data: PropTypes.instanceOf(GridLoaderViewModel),
};
export default function GridLoader(props: InferProps<typeof ControlPropType>) {
  const { data } = props;
  let count = data?.count ?? 10;
  let items = [];
  for (let index = 1; index <= count; index++) {
    items.push(index);
  }
  return (
    <>
      <div className="row">
        {items.map((item) => {
          return (
            <div className="col s12 m6 l4" key={item}>
              <div className="card sticky-action">
                <div className="card-image waves-effect waves-block waves-light bg-sky-500">
                  <Skeleton style={{ height: 250 }} />
                </div>
                <div className="card-content">
                  <span className="card-title activator grey-text text-darken-4">
                    <Skeleton count={1} />
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
GridLoader.defaultProps = ControlPropType;
