import BaseApi from "../../Core/BaseApi";

export default class CollegeFacilityService extends BaseApi {

    public static Facilities(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/college_facility/facilities`, model, null, callBack);
    }

    public static Check(model: any, callBack: any) {
        this.PostRequest(`${this.SUPER_ADMIN_PATH}/college_facility/check`, model, null, callBack);
    }

}