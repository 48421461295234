
import { Navigate, Outlet } from "react-router-dom";
import Constant from "../Core/Constant";
import Helper from "../Core/Helper";
import SessionHelper from "../Core/SessionHelper";
import UserModel, { UserRole } from "../Models/UserModel";

export default function UserLayout() {


    const auth = SessionHelper.GetSession<UserModel>();
    
    if (!auth) {
        return <Navigate to={Constant.LOGIN_PATH} />
    }

    if (auth.role !== UserRole.super) {
        return Helper.NavigateRedirect(auth.role);
    }


    return (
        <main>
            <Outlet />
        </main>
    )

}