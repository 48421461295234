import { ReactNode } from "react";
import MasterControl from "../../../Components/MasterControl";
import { TextCreateUpdate, withParams } from "../../../Components/PageHelper";
import BaseComponent from "../../../Core/BaseComponent";
import BaseCore from "../../../Core/BaseCore";
import { BaseResponse } from "../../../Core/BaseResponse";
import BaseState from "../../../Core/BaseState";
import DesignationService from "../../../DataServices/Super/DesignationService";
import DesignationModel from "../../../Models/DesignationModel";
import DesignationCreateUpdateViewModel from "../../../ViewModels/Designation/DesignationCreateUpdateViewModel";
class DesignationCreateUpdatePage extends BaseComponent<
  any,
  DesignationCreateUpdateViewModel
> {
  constructor(props: any) {
    super(props);
    this.state = new BaseState<DesignationCreateUpdateViewModel>(
      new DesignationCreateUpdateViewModel()
    );
  }
  componentDidMount() {
    var id = this.props?.params?.id;
    if (id) {
      this.Details(id);
    }
  }
  Details(id: number) {
    this.ShowDataLoader(true);
    DesignationService.Details({ id: id }, (res: BaseResponse<any>) => {
      this.ShowDataLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      BaseCore.ObjectAssign(this.state.model, res.data);
      this.UpdateViewModel();
    });
  }
  SetFile = (event: any) => {
    let file = event.target.files[0];
    this.state.model.file = file;
    this.UpdateViewModel();
  };
  Create = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new DesignationModel());
    this.ShowDataWithFileSubmitLoader(true);
    DesignationService.Create(
      model,
      this.SetConfig,
      (res: BaseResponse<any>) => {
        this.ShowDataWithFileSubmitLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.ShowToast(res.message, "success");
        this.GoBack();
      }
    );
  };
  Update = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new DesignationModel());
    if (!window.confirm("Are you sure ?")) {
      return false;
    }
    this.ShowDataWithFileSubmitLoader(true);
    DesignationService.Update(
      model,
      this.SetConfig,
      (res: BaseResponse<any>) => {
        this.ShowDataWithFileSubmitLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.ShowToast(res.message, "success");
        this.GoBack();
      }
    );
  };
  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <div className="row">
          <div
            id="breadcrumbs-wrapper"
            data-image={`${process.env.PUBLIC_URL}/assets/images/gallery/breadcrumb-bg.jpg`}
          >
            <div className="container">
              <div className="row">
                <div className="col s12 m6 l6">
                  <h5 className="breadcrumbs-title mt-0 mb-0">
                    <span>Designation</span>
                  </h5>
                </div>
                <div className="col s12 m6 l6 right-align-md">
                  <ol className="breadcrumbs mb-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Master</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#!">Designation</a>
                    </li>
                    <li className="breadcrumb-item active">
                      {TextCreateUpdate(model.id)}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div id="input-fields" className="card card-tabs">
                <div className="card-content">
                  <div className="card-title">
                    <div className="row">
                      <div className="col s12 m6 l6">
                        <h4 className="card-title">
                          Designation {TextCreateUpdate(model.id)}
                        </h4>
                      </div>
                      <div className="col s12 m6 l6">
                        <button className="btn right" onClick={this.GoBack}>
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="view-input-fields">
                    <div className="row">
                      <div className="col s12">
                        <form
                          className="formValidate0"
                          autoComplete="off"
                          onSubmit={model.id ? this.Update : this.Create}
                        >
                          <div className="row">
                            <div className="input-field col s12 l6">
                              <label
                                htmlFor="name"
                                className={model.name && "active"}
                              >
                                Designation Name
                              </label>
                              <input
                                required
                                id="name"
                                name="name"
                                onChange={this.SetCustomeState}
                                value={model.name}
                                type="text"
                              />
                            </div>
                            <div className="input-field col s12 l6">
                              <label
                                htmlFor="abbreviation"
                                className={model.abbreviation && "active"}
                              >
                                Abbreviation
                              </label>
                              <input
                                id="abbreviation"
                                name="abbreviation"
                                onChange={this.SetCustomeState}
                                value={model.abbreviation}
                                type="text"
                              />
                            </div>
                            <div className="file-field input-field col s12">
                              <div className="btn">
                                <span>Choose File</span>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={this.SetFile}
                                />
                              </div>
                              <div className="file-path-wrapper">
                                <input
                                  className="file-path validate valid"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="input-field col s12">
                              <button
                                className="btn waves-effect waves-light right bg-sky-500"
                                type="submit"
                                name="action"
                              >
                                Submit
                                <i className="material-icons right">
                                  {model.id ? "edit" : "add"}
                                </i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MasterControl data={model} />
      </>
    );
  }
}
export default withParams(DesignationCreateUpdatePage);
