import React, { useState } from 'react';
import PropTypes from "prop-types";

const NoShow = {
    display: "none",
    height: 0,
    width: 0
}

export default function CustomImage(props: any) {
    const {
        src,
        style,
        className,
        onClick,
        alt,
        width,
        height,
        ...rest
    } = props;

    const [progress, setProgress] = useState(0);
    const [complete, setcomplete] = useState(false);

    // const onStart = (event)=>{ for first imaga
    //     setcomplete(false)
    // }

    const onProgress = (event: any) => {
        setProgress(event.loaded);
        console.log(progress);
    }

    const onLoadEnd = () => {
        setcomplete(true);
    }

    var tempImage = require('./Assets/loader.gif');
    if (!src) {
        tempImage = require('./Assets/no_preview.png');
    }

    return (
        <>
            {
                !complete &&
                <img src={tempImage}
                    onClick={onClick}
                    style={style}
                    alt={alt ?? "alt"}
                    className={className}
                    width={width}
                    height={height}
                />

            }

            <img src={src}
                //onLoadStart={onStart}
                onProgress={onProgress}
                //onLoadEnd={onLoadEnd}
                onLoad={onLoadEnd}
                {...props}
                {...rest}
                style={complete ? style : NoShow}
                alt={alt ?? "alt"}
                className={className}
                width={width}
                height={height}
            />
        </>
    )
}

CustomImage.propTypes = {
    src: PropTypes.any,
    style: PropTypes.any,
    resizeMode: PropTypes.any,
    alt: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number
};

