import BaseViewModel from "../../Core/BaseViewModel";
import { CollegeFacilityData } from "../../Models/CollegeFacilityModel";


export default class CollegeFacilityChildViewModel extends BaseViewModel {

    college_id?: number;
    facility_id?: number;
    data: CollegeFacilityData[] = [];

}
