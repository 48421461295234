import BaseModel from "../Core/BaseModel";
import CountryModel from "./CountryModel";
import StateModel from "./StateModel";

export default class DistrictModel extends BaseModel {
    country_id?: number;
    state_id?: number;
    name?: string;
    abbreviation?: string;
    image_path?: string;
    country?: CountryModel;
    state?: StateModel;

    //Requset
    file?: File;
}