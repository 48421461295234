import BaseApi from "../../Core/BaseApi";

export default class UserService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/user/list`, model,null, callBack);
    }

    
    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/user/details`, model,null, callBack);
    }

    public static Create(model: any, callBack: any) {
        this.PostRequest(`${this.SUPER_ADMIN_PATH}/user/create`, model,null, callBack);
    }

    public static Update(model: any, callBack: any) {
        this.PutRequest(`${this.SUPER_ADMIN_PATH}/user/update`, model,null, callBack);
    }

    public static Login(model: any, callBack: any) {
        this.PostRequest(`${this.API_PATH}/user/login`, model,null, callBack);
    }

    public static Logout(callBack: any) {
        this.PostRequest(`${this.API_PATH}/user/logout`, null,null, callBack);
    }

}