import BaseViewModel from "../../Core/BaseViewModel";
import EnumResponseModel from "../../Core/EnumResponseModel";

export default class FacilityCreateUpdateViewModel extends BaseViewModel {
    category?: string;
    name?: string;
    image_path?: string;

    categories: EnumResponseModel[] = [];

    //Request
    file?: File;
}