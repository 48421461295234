import BaseViewModel from "../../Core/BaseViewModel";
import EnumResponseModel from "../../Core/EnumResponseModel";
import CityModel from "../../Models/CityModel";
import CountryModel from "../../Models/CountryModel";
import DistrictModel from "../../Models/DistrictModel";
import StateModel from "../../Models/StateModel";

export default class UniversityCreateUpdateViewModel extends BaseViewModel {
    slug?: string;
    name?: string;
    abbreviation?:string;
    country_id?: number;
    state_id?: number;
    district_id?: number;
    city_id?: number;
    approved_by: any[] = [];
    type:any;
    address?:string;
    logo_path?: string;
    est_date?: Date;
    est_year?: number;

    //For bind
    countries: CountryModel[] = [];
    all_states:StateModel[]=[];
    states: StateModel[] = [];
    all_districts:DistrictModel[]=[];
    districts:DistrictModel[]=[];
    all_city:CityModel[]=[];
    cities:CityModel[]=[];
    approved_by_list: EnumResponseModel[] = [];
    types: EnumResponseModel[] = [];


    //Request
    file?: File;
}