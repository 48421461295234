import BaseViewModel from '../../Core/BaseViewModel';
import CategoryChildViewModel from './CollegeCategoryChildViewModel';
import CollegeCourseChildViewModel from './CollegeCourseChildViewModel';
import CollegeCreateUpdateChildViewModel from './CollegeCreateUpdateChildViewModel';
import CollegeDepartmentChildViewModel from './CollegeDepartmentChildViewModel';
import CollegeFacilityChildViewModel from './CollegeFacilityChildViewModel';
import CollegeGalleryChildViewModel from './CollegeGalleryChildViewModel';
import EmployeeChildViewModel from './EmployeeChildViewModel';

export default class CollegeCreateUpdateViewModel extends BaseViewModel {
  name?: string;
  tabs: TabModel[] = tabs;
  tab_layout: any = null;
  profile_data = new CollegeCreateUpdateChildViewModel();
  category_data = new CategoryChildViewModel();
  course_data = new CollegeCourseChildViewModel();
  department_data = new CollegeDepartmentChildViewModel();
  gallery_data = new CollegeGalleryChildViewModel();
  faculty_data = new EmployeeChildViewModel();
  facility_data = new CollegeFacilityChildViewModel();
}

class TabModel {
  label: string = null as any;
  tab: UITab = null as any;
  is_active: boolean = false;
}

export enum UITab {
  profile,
  category,
  course,
  department,
  gallery,
  facility,
  faculty,
}

const tabs: TabModel[] = [
  { label: 'Profile', tab: UITab.profile, is_active: true },
  { label: 'Category', tab: UITab.category, is_active: false },
  { label: 'Course', tab: UITab.course, is_active: false },
  { label: 'Department', tab: UITab.department, is_active: false },
  { label: 'Gallery', tab: UITab.gallery, is_active: false },
  { label: 'Facility', tab: UITab.facility, is_active: false },
  { label: 'Faculty', tab: UITab.faculty, is_active: false },
];
