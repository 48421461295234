import BaseModel from "../Core/BaseModel";
import CountryModel from "./CountryModel";

export default class StateModel extends BaseModel {
    country_id?: number;
    name?: string;
    abbreviation?:string;
    image_path?: string;
    country?: CountryModel;

    //Requset
    file?: File;
}