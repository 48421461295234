import moment from "moment";
import { useParams } from "react-router-dom";
export const CustomeDate = (date: any) => {
    return moment(date).format('D,MMM-YYYY');
}

export const TableOption: any = {
    pageSize: 10,
    exportButton: true,
    pageSizeOptions: [10, 20, 30, 50, 100],
}

export const CustomeDateBy = (date: any, by?: any) => {
    return moment(date).format('D,MMM-YYYY');
}

export const ToDate = (date: any, format?: string) => {
    format = format ?? "D-MMMM-YYYY";
    return moment(date).format(format);
}


export const ToDecimal = (value: any) => {
    value = value ?? 0;
    return value.toFixed(2);
}



export const TextCreateUpdate = (id?: any) => {
    return (
        <span>
            {!id && <span>Create</span>}
            {id && <span>Update</span>}
        </span>
    )
}

export const ModalImage = (src?: any, alt?: string) => {
    return (
        <img src={src} alt={alt ?? "image"} style={{ width: 150, height: 150 }} />
    )
}

export function withParams(Component: any) {
    return (props: JSX.IntrinsicAttributes) => <Component {...props} params={useParams()} />;
}
