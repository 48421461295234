import Constant from "../../Core/Constant";
import PagePath from "../../Core/PagePath";
import CategoryCreateUpdatePage from "../Super/Category/CategoryCreateUpdatePage";
import CategoryListPage from "../Super/Category/CategoryListPage";
import CityCreateUpdatePage from "../Super/City/CityCreateUpdatePage";
import CityListPage from "../Super/City/CityListPage";
import CollegeCreateUpdatePage from "../Super/College/CollegeCreateUpdatePage";
import CollegeListPage from "../Super/College/CollegeListPage";
import CountryCreateUpdatePage from "../Super/Country/CountryCreateUpdatePage";
import CountryListPage from "../Super/Country/CountryListPage";
import CourseCreateUpdatePage from "../Super/Course/CourseCreateUpdatePage";
import CourseListPage from "../Super/Course/CourseListPage";
import DashboardPage from "../Super/Dashboard/DashboardPage";
import DepartmentCreateUpdatePage from "../Super/Department/DepartmentCreateUpdatePage";
import DepartmentListPage from "../Super/Department/DepartmentListPage";
import DesignationCreateUpdatePage from "../Super/Designation/DesignationCreateUpdatePage";
import DesignationListPage from "../Super/Designation/DesignationListPage";
import DistrictCreateUpdatePage from "../Super/District/DistrictCreateUpdatePage";
import DistrictListPage from "../Super/District/DistrictListPage";
import FacilityCreateUpdatePage from "../Super/Facility/FacilityCreateUpdatePage";
import FacilityListPage from "../Super/Facility/FacilityListPage";
import StateCreateUpdatePage from "../Super/State/StateCreateUpdatePage";
import StateListPage from "../Super/State/StateListPage";
import UniversityCreateUpdatePage from "../Super/University/UniversityCreateUpdatePage";
import UniversityListPage from "../Super/University/UniversityListPage";
import UserCreateUpdatePage from "../Super/User/UserCreateUpdatePage";
import UserListPage from "../Super/User/UserListPage";


const path = Constant.SUPER_ADMIN_PATH;

export const SuperAdminMenuRoutes: Array<PagePath> = [
    new PagePath(path, DashboardPage, "Dashboard", "dashboard"),
    new PagePath(`${path}/user/list`, UserListPage, "Users", "user"),
    new PagePath(`${path}/category/list`, CategoryListPage, "Categories", "category"),
    new PagePath(`${path}/course/list`, CourseListPage, "Courses", "category"),
    new PagePath(`${path}/department/list`, DepartmentListPage, "Departments", "category"),
    new PagePath(`${path}/university/list`, UniversityListPage, "Universities", "Universities"),
    new PagePath(`${path}/designation/list`, DesignationListPage, "Designations", "designation"),
    new PagePath(`${path}/country/list`, CountryListPage, "Countries", "category"),
    new PagePath(`${path}/state/list`, StateListPage, "States", "category"),
    new PagePath(`${path}/district/list`, DistrictListPage, "Districts", "category"),
    new PagePath(`${path}/city/list`, CityListPage, "Cities", "category"),
    new PagePath(`${path}/facility/list`, FacilityListPage, "Facilities", "facilities"),
    new PagePath(`${path}/college/list`, CollegeListPage, "Colleges", "category"),

];

export const SuperAdminRoutes: Array<PagePath> = [
    new PagePath(`${path}/user/create`, UserCreateUpdatePage),
    new PagePath(`${path}/user/update/:id`, UserCreateUpdatePage),
    new PagePath(`${path}/category/create`, CategoryCreateUpdatePage),
    new PagePath(`${path}/category/update/:id`, CategoryCreateUpdatePage),
    new PagePath(`${path}/course/create`, CourseCreateUpdatePage),
    new PagePath(`${path}/course/update/:id`, CourseCreateUpdatePage),
    new PagePath(`${path}/department/create`, DepartmentCreateUpdatePage),
    new PagePath(`${path}/department/update/:id`, DepartmentCreateUpdatePage),
    new PagePath(`${path}/country/create`, CountryCreateUpdatePage),
    new PagePath(`${path}/country/update/:id`, CountryCreateUpdatePage),
    new PagePath(`${path}/state/create`, StateCreateUpdatePage),
    new PagePath(`${path}/state/update/:id`, StateCreateUpdatePage),
    new PagePath(`${path}/district/create`, DistrictCreateUpdatePage),
    new PagePath(`${path}/district/update/:id`, DistrictCreateUpdatePage),
    new PagePath(`${path}/city/create`, CityCreateUpdatePage),
    new PagePath(`${path}/city/update/:id`, CityCreateUpdatePage),
    new PagePath(`${path}/facility/create`, FacilityCreateUpdatePage),
    new PagePath(`${path}/facility/update/:id`, FacilityCreateUpdatePage),
    new PagePath(`${path}/university/create`, UniversityCreateUpdatePage),
    new PagePath(`${path}/university/update/:id`, UniversityCreateUpdatePage),
    new PagePath(`${path}/designation/create`, DesignationCreateUpdatePage),
    new PagePath(`${path}/designation/update/:id`, DesignationCreateUpdatePage),
    new PagePath(`${path}/college/create`, CollegeCreateUpdatePage),
    new PagePath(`${path}/college/update/:id`, CollegeCreateUpdatePage),
];

export default SuperAdminRoutes;