import { ReactNode } from "react";
import MasterControl from "../../../Components/MasterControl";
import { TextCreateUpdate, withParams } from "../../../Components/PageHelper";
import BaseComponent from "../../../Core/BaseComponent";
import BaseCore from "../../../Core/BaseCore";
import { BaseResponse } from "../../../Core/BaseResponse";
import BaseState from "../../../Core/BaseState";
import Helper from "../../../Core/Helper";
import UniversityService from "../../../DataServices/Super/UniversityService";
import { ApprovedBy, InstituteType } from "../../../Models/EumModel";
import UniversityModel from "../../../Models/UniversityModel";
import UniversityCreateUpdateViewModel from "../../../ViewModels/University/UniversityCreateUpdateViewModel";
class UniversityCreateUpdatePage extends BaseComponent<
  any,
  UniversityCreateUpdateViewModel
> {
  constructor(props: any) {
    super(props);
    this.state = new BaseState<UniversityCreateUpdateViewModel>(
      new UniversityCreateUpdateViewModel()
    );
  }
  componentDidMount() {
    if (!this.isLoadComponent) {
      this.isLoadComponent = true;
      var id = this.props?.params?.id;
      this.state.model.approved_by_list = BaseCore.ToEnumList(ApprovedBy);
      this.state.model.types = BaseCore.ToEnumList(InstituteType);
      this.UpdateViewModel();
      this.InitializeForm();
      if (id) {
        this.Details(id);
      }
    }
  }
  shouldComponentUpdate(nextProps: any, nextState: any) {
    var model = this.state.model;
    nextState.model.states = model.all_states.filter(
      (i) => i.country_id === Number(model.country_id)
    );
    nextState.model.districts = model.all_districts.filter(
      (i) => i.state_id === Number(model.state_id)
    );
    nextState.model.cities = model.all_city.filter(
      (i) => i.district_id === Number(model.district_id)
    );
    return true;
  }
  InitializeForm() {
    this.ShowDataLoader(true);
    UniversityService.InitializeForm((res: BaseResponse<any>) => {
      this.ShowDataLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      let data = res.data as any;
      this.state.model.countries = data.countries;
      this.state.model.all_states = data.states;
      this.state.model.states = data.states;
      this.state.model.all_districts = data.districts;
      this.state.model.districts = data.districts;
      this.state.model.cities = data.cities;
      this.state.model.all_city = data.cities;
      this.UpdateViewModel();
    });
  }
  Details(id: number) {
    this.ShowDataLoader(true);
    UniversityService.Details({ id: id }, (res: BaseResponse<any>) => {
      this.ShowDataLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      BaseCore.ObjectAssign(this.state.model, res.data);
      this.UpdateViewModel();
    });
  }
  CheckedApprovedBy = (value: number) => {
    this.state.model.approved_by = Helper.ArrayAddRemove(
      this.state.model.approved_by,
      value
    );
    this.UpdateViewModel();
  };
  SetFile = (event: any) => {
    let file = event.target.files[0];
    this.state.model.file = file;
    this.UpdateViewModel();
  };
  Create = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(
      this.state.model,
      new UniversityModel()
    ) as any;
    model.approved_by = JSON.stringify(model.approved_by);
    this.ShowDataWithFileSubmitLoader(true);
    UniversityService.Create(
      model,
      this.SetConfig,
      (res: BaseResponse<any>) => {
        this.ShowDataWithFileSubmitLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.ShowToast(res.message, "success");
        this.GoBack();
      }
    );
  };
  Update = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(
      this.state.model,
      new UniversityModel()
    ) as any;
    model.approved_by = JSON.stringify(model.approved_by);
    if (!window.confirm("Are you sure ?")) {
      return false;
    }
    this.ShowDataWithFileSubmitLoader(true);
    UniversityService.Update(
      model,
      this.SetConfig,
      (res: BaseResponse<any>) => {
        this.ShowDataWithFileSubmitLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.ShowToast(res.message, "success");
        this.GoBack();
      }
    );
  };
  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <div className="row">
          <div
            id="breadcrumbs-wrapper"
            data-image={`${process.env.PUBLIC_URL}/assets/images/gallery/breadcrumb-bg.jpg`}
          >
            <div className="container">
              <div className="row">
                <div className="col s12 m6 l6">
                  <h5 className="breadcrumbs-title mt-0 mb-0">
                    <span>University</span>
                  </h5>
                </div>
                <div className="col s12 m6 l6 right-align-md">
                  <ol className="breadcrumbs mb-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Master</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#!">University</a>
                    </li>
                    <li className="breadcrumb-item active">
                      {TextCreateUpdate(model.id)}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div id="input-fields" className="card card-tabs">
                <div className="card-content">
                  <div className="card-title">
                    <div className="row">
                      <div className="col s12 m6 l6">
                        <h4 className="card-title">
                          University {TextCreateUpdate(model.id)}
                        </h4>
                      </div>
                      <div className="col s12 m6 l6">
                        <button className="btn right" onClick={this.GoBack}>
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="view-input-fields">
                    <div className="row">
                      <div className="col s12">
                        <form
                          className="formValidate0"
                          autoComplete="off"
                          onSubmit={model.id ? this.Update : this.Create}
                        >
                          <div className="row">
                            <div className="input-field col s12 l8">
                              <label
                                htmlFor="name"
                                className={model.name && "active"}
                              >
                                University Name
                              </label>
                              <input
                                required
                                id="name"
                                name="name"
                                onChange={this.SetCustomeState}
                                value={model.name}
                                type="text"
                              />
                            </div>
                            <div className="input-field col s12 l4">
                              <label
                                htmlFor="abbreviation"
                                className={model.abbreviation && "active"}
                              >
                                Abbreviation
                              </label>
                              <input
                                required
                                id="abbreviation"
                                name="abbreviation"
                                onChange={this.SetCustomeState}
                                value={model.abbreviation}
                                type="text"
                              />
                            </div>
                            <div className="input-field col s12 l12">
                              <p className="mb-1">
                                Approved By:
                                {model.approved_by_list.map((item, index) => {
                                  return (
                                    <p className="mb-1" key={index}>
                                      <label>
                                        <input
                                          id="indeterminate-checkbox"
                                          type="checkbox"
                                          onChange={() =>
                                            this.CheckedApprovedBy(item.value)
                                          }
                                          checked={model.approved_by.includes(
                                            item.value
                                          )}
                                        />
                                        <span>{item.name.toUpperCase()}</span>
                                      </label>
                                    </p>
                                  );
                                })}
                              </p>
                            </div>
                            <div
                              className="input-field  col s12 l4"
                              data-select2-id={111}
                            >
                              <select
                                name="type"
                                value={model.type}
                                onChange={this.SetCustomeState}
                                className="select2 browser-default"
                                data-select2-id={1}
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value="" data-select2-id={3}>
                                  ---Select Type---
                                </option>
                                {model.types.map((item, index) => {
                                  return (
                                    <option value={item.value} key={index}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="input-field col s12 l4">
                              <label htmlFor="est_date" className="active">
                                Established date
                              </label>
                              <input
                                id="est_date"
                                name="est_date"
                                onChange={this.SetCustomeState}
                                value={model.est_date
                                  ?.toString()
                                  ?.substring(0, 10)}
                                type="date"
                              />
                            </div>
                            <div className="input-field col s12 l4">
                              <label
                                htmlFor="est_year"
                                className={model.est_year ? "active" : ""}
                              >
                                Established Year
                              </label>
                              <input
                                id="est_year"
                                name="est_year"
                                onChange={this.SetCustomeState}
                                value={model.est_year}
                                type="number"
                              />
                            </div>
                            <div className="file-field input-field col s12">
                              <div className="btn">
                                <span>Choose File</span>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={this.SetFile}
                                />
                              </div>
                              <div className="file-path-wrapper">
                                <input
                                  className="file-path validate valid"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div
                              className="input-field  col s12 l3"
                              data-select2-id={111}
                            >
                              <select
                                name="country_id"
                                value={model.country_id}
                                onChange={this.SetCustomeState}
                                className="select2 browser-default"
                                data-select2-id={1}
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value="" data-select2-id={3}>
                                  ---Select Country---
                                </option>
                                {model.countries.map((item, index) => {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              className="input-field  col s12 l3"
                              data-select2-id={111}
                            >
                              <select
                                name="state_id"
                                value={model.state_id}
                                onChange={this.SetCustomeState}
                                className="select2 browser-default"
                                data-select2-id={1}
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value="" data-select2-id={3}>
                                  ---Select State---
                                </option>
                                {model.states.map((item, index) => {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              className="input-field  col s12 l3"
                              data-select2-id={111}
                            >
                              <select
                                name="district_id"
                                value={model.district_id}
                                onChange={this.SetCustomeState}
                                className="select2 browser-default"
                                data-select2-id={1}
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value="" data-select2-id={3}>
                                  ---Select District---
                                </option>
                                {model.districts.map((item, index) => {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              className="input-field  col s12 l3"
                              data-select2-id={111}
                            >
                              <select
                                name="city_id"
                                value={model.city_id}
                                onChange={this.SetCustomeState}
                                className="select2 browser-default"
                                data-select2-id={1}
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value="" data-select2-id={3}>
                                  ---Select City---
                                </option>
                                {model.cities.map((item, index) => {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="input-field col s12 l12">
                              <label
                                htmlFor="address"
                                className={model.address && "active"}
                              >
                                Address
                              </label>
                              <textarea
                                className="materialize-textarea"
                                required
                                id="address"
                                name="address"
                                value={model.address}
                                onChange={this.SetCustomeState}
                              ></textarea>
                            </div>
                            <div className="input-field col s12">
                              <button
                                className="btn waves-effect waves-light right bg-sky-500"
                                type="submit"
                                name="action"
                              >
                                Submit
                                <i className="material-icons right">
                                  {model.id ? "edit" : "add"}
                                </i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MasterControl data={model} />
      </>
    );
  }
}
export default withParams(UniversityCreateUpdatePage);
