import { ReactNode } from "react";
import CustomImage from "../../../Components/CustomImage";
import BaseComponent from "../../../Core/BaseComponent";

export default class DashboardPage extends BaseComponent<any,any> {

    render(): ReactNode {
        return(
            <>
            <h1> Admin</h1>
            <CustomImage src={`${process.env.PUBLIC_URL}/assets/images/icon/jpg-image.png`} width={24} height={30} alt="sample image" />
            </>
        )
    }

}