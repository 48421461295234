import BaseViewModel from "../../Core/BaseViewModel";
import CountryModel from "../../Models/CountryModel";
import DistrictModel from "../../Models/DistrictModel";
import StateModel from "../../Models/StateModel";

export default class CityCreateUpdateViewModel extends BaseViewModel {
    country_id?: number;
    state_id?: number;
    district_id?: number;
    name?: string;
    abbreviation?: string;
    image_path?: string;

    countries: CountryModel[] = [];
    all_states:StateModel[]=[];
    states: StateModel[] = [];
    all_districts:DistrictModel[]=[];
    districts:DistrictModel[]=[];

    //Request
    file?: File;
}