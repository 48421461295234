import BaseApi from "../../Core/BaseApi";

export default class EmployeeService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/employee/list`, model, null, callBack);
    }

    public static InitializeForm(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/employee/initialize_form`, model, null, callBack);
    }

    public static Create(model: any, headers: any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/employee/create`, model, headers, callBack);
    }

    public static Update(model: any, headers: any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/employee/update`, model, headers, callBack);
    }

    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/employee/details`, model, null, callBack);
    }

    public static Delete(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/employee/delete`, model, null, callBack);
    }

    public static AddAdditionalDesignation(model: any, callBack: any) {
        this.PostRequest(`${this.SUPER_ADMIN_PATH}/employee/add_additional_designation`, model, null, callBack);
    }

    public static DeleteAdditionalDesignation(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/employee/delete_additional_designation`, model, null, callBack);
    }
}