import PropTypes, { InferProps } from 'prop-types';
import BaseViewModel from '../Core/BaseViewModel';
import HashLoader from 'react-spinners/HashLoader';
import BounceLoader from 'react-spinners/BounceLoader';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNProgress } from '@tanem/react-nprogress';

const ControlPropType = {
  data: PropTypes.instanceOf(BaseViewModel).isRequired,
};

export default function PageLoader(props: InferProps<typeof ControlPropType>) {
  const { data } = props;
  var model = data;
  const value = model.progress;
  return (
    <>
      <Progress isAnimating={model.is_data_loading} />
      {model.is_data_submit && <LoadingAnimation />}
      {model.is_data_with_file_submit && <ProgressBar value={value} />}
    </>
  );
}

function LoadingAnimation() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        outline: 'dashed 1px black',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: 1100,
        background: '#00000099',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          background: '',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <HashLoader size={120} color="white" />
      </div>
    </div>
  );
}

function ProgressBar(props: any) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        outline: 'dashed 1px black',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: 1100,
        background: '#00000099',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          background: '',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          style={{
            width: '150px',
            height: '150px',
          }}
        >
          <CircularProgressbarWithChildren value={props.value}>
            <BounceLoader size={120} color="white" />
            <div
              style={{
                fontSize: 25,
                marginTop: 0,
                position: 'fixed',
              }}
            >
              <strong
                style={{
                  color: 'black',
                }}
              >
                {props.value}%
              </strong>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </div>
  );
}

const Bar: React.FC<{ animationDuration: number; progress: number }> = ({
  animationDuration,
  progress,
}) => (
  <div
    style={{
      background: '#29d',
      height: 2,
      left: 0,
      marginLeft: `${(-1 + progress) * 100}%`,
      position: 'fixed',
      top: 0,
      transition: `margin-left ${animationDuration}ms linear`,
      width: '100%',
      zIndex: 1031,
    }}
  >
    <div
      style={{
        boxShadow: '0 0 10px #29d, 0 0 5px #29d',
        display: 'block',
        height: '100%',
        opacity: 1,
        position: 'absolute',
        right: 0,
        transform: 'rotate(3deg) translate(0px, -4px)',
        width: 100,
      }}
    />
  </div>
);

const Container: React.FC<{
  animationDuration: number;
  isFinished: boolean;
  children: any;
}> = ({ animationDuration, children, isFinished }) => (
  <div
    style={{
      opacity: isFinished ? 0 : 1,
      pointerEvents: 'none',
      transition: `opacity ${animationDuration}ms linear`,
    }}
  >
    {children}
  </div>
);

const Progress: React.FC<{ isAnimating: boolean }> = ({ isAnimating }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
      {/*
      Place For spinner component If neede In Future
      */}
    </Container>
  );
};

PageLoader.defaultProps = ControlPropType;
