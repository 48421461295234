import BaseModel from "../Core/BaseModel";
import CourseModel from "./CourseModel";

export default class CollegeCourseModel extends BaseModel {

    college_id?: number;
    course_id?: number;
    seats?: number;
    total_fees_amount?: number;
    average_fees_amount?: number;
    admission_fees_amount?: number;
    semester_fees_amount?: number;
    fees_description?: string;
    course?: CourseModel;

}