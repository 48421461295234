import BaseViewModel from "../../Core/BaseViewModel";

export default class DesignationCreateUpdateViewModel extends BaseViewModel {
    name?: string;
    slug?:string;
    abbreviation?: string;
    image_path?: string;

    //Request
    file?: File;
}