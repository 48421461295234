import BaseApi from "../../Core/BaseApi";

export default class DesignationService extends BaseApi {

    public static List(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/designation/list`, model, null, callBack);
    }

    public static Create(model: any, headers: any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/designation/create`, model, headers, callBack);
    }

    public static Update(model: any, headers: any, callBack: any) {
        this.FileRequest(`${this.SUPER_ADMIN_PATH}/designation/update`, model, headers, callBack);
    }

    public static Details(model: any, callBack: any) {
        this.GetRequest(`${this.SUPER_ADMIN_PATH}/designation/details`, model, null, callBack);
    }

    public static Delete(model: any, callBack: any) {
        this.DeleteRequest(`${this.SUPER_ADMIN_PATH}/designation/delete`, model, null, callBack);
    }
}