import { ReactNode } from "react";
import BaseComponent from "../../../Core/BaseComponent";
import { UserRole } from "../../../Models/UserModel";

export default class DashboardPage extends BaseComponent<any, any> {

    render(): ReactNode {
        return (
            <>
                <h1>{UserRole[this.AUTH.role]}</h1>
            </>
        )
    }

}