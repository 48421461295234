import { Route, Routes } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import AdminRoutes, { AdminMenuRoutes } from "./Routes/admin_routes";
import StudentRoutes, { StudentMenuRoutes } from "./Routes/student_routes";
import SuperAdminRoutes, { SuperAdminMenuRoutes } from "./Routes/super_admin_routes";
import UserRoutes, { UserMenuRoutes } from "./Routes/user_routes";
import StudentLayout from "./StudentLayout";
import SuperAdminLayout from "./SuperAdminLayout";
import UserLayout from "./UserLayout";
import UserLogin from "./UserLogin";




export default function AppStart(props: any) {
    return (
        <Routes>
            <Route path="/login" element={<UserLogin  {...props} />} />


            <Route path="/super_admin" element={<SuperAdminLayout {...props} />}>
                {SuperAdminMenuRoutes.map((item, index) => {
                    return <Route key={index} path={item.path} element={<item.component  {...props} />} />
                })}
                {SuperAdminRoutes.map((item, index) => {
                    return <Route key={index} path={item.path} element={<item.component  {...props} />} />
                })}
            </Route>


            <Route path="/admin" element={<AdminLayout {...props} />}>
                {AdminMenuRoutes.map((item, index) => {
                    return <Route key={index} path={item.path} element={<item.component  {...props} />} />
                })}
                {AdminRoutes.map((item, index) => {
                    return <Route key={index} path={item.path} element={<item.component {...props} />} />
                })}
            </Route>


            <Route path="/user" element={<UserLayout {...props} />}>
                {UserMenuRoutes.map((item, index) => {
                    return <Route key={index} path={item.path} element={<item.component  {...props} />} />
                })}
                {UserRoutes.map((item, index) => {
                    return <Route key={index} path={item.path} element={<item.component {...props} />} />
                })}
            </Route>

            <Route path="/" element={<StudentLayout {...props} />}>
                {StudentMenuRoutes.map((item, index) => {
                    return <Route key={index} path={item.path} element={<item.component  {...props} />} />
                })}
                {StudentRoutes.map((item, index) => {
                    return <Route key={index} path={item.path} element={<item.component {...props} />} />
                })}
            </Route>


        </Routes>
    )
}