import Constant from "../../Core/Constant";
import PagePath from "../../Core/PagePath";
import DashboardPage from "../Student/Dashboard/DashboardPage";

const path = Constant.STUDENT_PATH;

export const StudentMenuRoutes: Array<PagePath> = [
    new PagePath(`${path}`, DashboardPage, "Dashboard", "dashboard"),

];

export const StudentRoutes: Array<PagePath> = [

];

export default StudentRoutes;