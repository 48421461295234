export enum ApprovedBy {
    ugc,
    aicte,
    aiu,
    mci
}

export enum InstituteType {
    government,
    private
}