import BaseModel from "../Core/BaseModel";
import CourseModel from "./CourseModel";
import DepartmentModel from "./DepartmentModel";

export default class CollegeDepartmentModel extends BaseModel {

    college_id?: number;
    course_id?: number;
    department_id?:number;
    seats?: number;
    total_fees_amount?: number;
    average_fees_amount?: number;
    admission_fees_amount?: number;
    semester_fees_amount?: number;
    fees_description?: string;
    course?: CourseModel;
    department?:DepartmentModel;
}