export default class SessionHelper {


    private static readonly auth: string = "session_auth_storage_key_for_collegeforme";

    public static SetSession<T>(data: T) {
        localStorage.setItem(SessionHelper.auth, JSON.stringify(data))
    }

    public static GetSession<T>(): T {
        var item = localStorage.getItem(SessionHelper.auth);
        if ((!item) || (item === "undefined") || (item === "null"))
            return null as unknown as T;

        return JSON.parse(item) as T;
    }

    public static GetToken(): string {
        return this.GetSession<any>()?.api_token;
    }
}