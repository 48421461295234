import { ReactNode } from "react";
import MasterControl from "../../../Components/MasterControl";
import BaseComponent from "../../../Core/BaseComponent";
import BaseCore from "../../../Core/BaseCore";
import { BaseResponse } from "../../../Core/BaseResponse";
import BaseState from "../../../Core/BaseState";
import Helper from "../../../Core/Helper";
import CollegeService from "../../../DataServices/Super/CollegeService";
import CollegeModel from "../../../Models/CollegeModel";
import { ApprovedBy, InstituteType } from "../../../Models/EumModel";
import CollegeCreateUpdateChildViewModel from "../../../ViewModels/College/CollegeCreateUpdateChildViewModel";
import { UITab } from "../../../ViewModels/College/CollegeCreateUpdateViewModel";
export default class CollegeCreateUpdateChildPage extends BaseComponent<
  any,
  CollegeCreateUpdateChildViewModel
> {
  constructor(props: any) {
    super(props);
    const { data } = props;
    this.state = new BaseState<CollegeCreateUpdateChildViewModel>(data);
  }
  componentDidMount() {
    if (!this.isLoadComponent) {
      this.isLoadComponent = true;
      const id = this.state.model.id;
      this.state.model.approved_by_list = BaseCore.ToEnumList(ApprovedBy);
      this.state.model.types = BaseCore.ToEnumList(InstituteType);
      this.UpdateViewModel();
      this.InitializeForm();
      if (id) {
        this.Details(id);
      }
    }
  }
  SetCollegeId = () => {
    this.props.SetCollegeId({
      college_id: this.state.model.id,
      tab: UITab.course,
    });
  };
  shouldComponentUpdate(nextProps: any, nextState: any) {
    var model = this.state.model;
    nextState.model.states = model.all_states.filter(
      (i) => i.country_id === Number(model.country_id)
    );
    nextState.model.districts = model.all_districts.filter(
      (i) => i.state_id === Number(model.state_id)
    );
    nextState.model.cities = model.all_city.filter(
      (i) => i.district_id === Number(model.district_id)
    );
    return true;
  }
  InitializeForm() {
    this.ShowDataLoader(true);
    CollegeService.InitializeForm((res: BaseResponse<any>) => {
      this.ShowDataLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      let data = res.data as any;
      this.state.model.countries = data.countries;
      this.state.model.all_states = data.states;
      this.state.model.states = data.states;
      this.state.model.all_districts = data.districts;
      this.state.model.districts = data.districts;
      this.state.model.cities = data.cities;
      this.state.model.all_city = data.cities;
      this.state.model.universities = data.universities;
      this.UpdateViewModel();
    });
  }
  Details(id: number) {
    this.ShowDataLoader(true);
    CollegeService.Details({ id: id }, (res: BaseResponse<any>) => {
      this.ShowDataLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      BaseCore.ObjectAssign(this.state.model, res.data);
      this.UpdateViewModel();
    });
  }
  CheckedApprovedBy = (value: number) => {
    this.state.model.approved_by = Helper.ArrayAddRemove(
      this.state.model.approved_by,
      value
    );
    this.UpdateViewModel();
  };
  SetLogoFile = (event: any) => {
    let file = event.target.files[0];
    this.state.model.logo_file = file;
    this.UpdateViewModel();
  };

  SetCoverFile = (event: any) => {
    let file = event.target.files[0];
    this.state.model.cover_image = file;
    this.UpdateViewModel();
  };
  Create = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new CollegeModel()) as any;
    model.approved_by = JSON.stringify(model.approved_by);
    this.ShowDataWithFileSubmitLoader(true);
    CollegeService.Create(model, this.SetConfig, (res: BaseResponse<any>) => {
      this.ShowDataWithFileSubmitLoader(false);
      console.log(res);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      var data = res.data as any;
      this.state.model.id = data.id;
      this.UpdateViewModel();
      this.SetCollegeId();
      this.ShowToast(res.message, "success");
    });
  };
  Update = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new CollegeModel()) as any;
    model.approved_by = JSON.stringify(model.approved_by);
    if (!window.confirm("Are you sure ?")) {
      return false;
    }
    this.ShowDataWithFileSubmitLoader(true);
    CollegeService.Update(model, this.SetConfig, (res: BaseResponse<any>) => {
      this.ShowDataWithFileSubmitLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
    });
  };
  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <form
          className="formValidate0 "
          autoComplete="off"
          onSubmit={model.id ? this.Update : this.Create}
        >
          <h5 className="text-xl text-black font-extrabold my-3">Basic info</h5>
          <div
            className="row "
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#eee",
            }}
          >
            <div className="input-field col s12 l8">
              <label htmlFor="name" className={model.name && "active"}>
                College Name
              </label>
              <input
                required
                id="name"
                name="name"
                onChange={this.SetCustomeState}
                value={model.name}
                type="text"
              />
            </div>
            <div className="input-field col s12 l4">
              <label
                htmlFor="abbreviation"
                className={model.abbreviation && "active"}
              >
                Abbreviation
              </label>
              <input
                required
                id="abbreviation"
                name="abbreviation"
                onChange={this.SetCustomeState}
                value={model.abbreviation}
                type="text"
              />
            </div>
            <div className="input-field col s12 l12">
              <p className="mb-1">
                Approved By:
                {model.approved_by_list.map((item, index) => {
                  return (
                    <p className="mb-1" key={index}>
                      <label>
                        <input
                          id="indeterminate-checkbox"
                          type="checkbox"
                          onChange={() => this.CheckedApprovedBy(item.value)}
                          checked={model.approved_by.includes(item.value)}
                        />
                        <span>{item.name.toUpperCase()}</span>
                      </label>
                    </p>
                  );
                })}
              </p>
            </div>
            <div className="input-field  col s12 l6" data-select2-id={111}>
              <select
                name="affiliations_university_id"
                value={model.affiliations_university_id}
                onChange={this.SetCustomeState}
                className="select2 browser-default"
                data-select2-id={1}
                tabIndex={-1}
                aria-hidden="true"
              >
                <option value="" data-select2-id={3}>
                  ---Select Affiliations---
                </option>
                {model.universities.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-field  col s12 l4" data-select2-id={111}>
              <select
                name="type"
                value={model.type}
                onChange={this.SetCustomeState}
                className="select2 browser-default"
                data-select2-id={1}
                tabIndex={-1}
                aria-hidden="true"
              >
                <option value="" data-select2-id={3}>
                  ---Select Type---
                </option>
                {model.types.map((item, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-field col s12 l4">
              <label htmlFor="est_date" className="active">
                Established date
              </label>
              <input
                id="est_date"
                name="est_date"
                onChange={this.SetCustomeState}
                value={model.est_date?.toString()?.substring(0, 10)}
                type="date"
              />
            </div>
            <div className="input-field col s12 l4">
              <label
                htmlFor="est_year"
                className={model.est_year ? "active" : ""}
              >
                Established Year
              </label>
              <input
                id="est_year"
                name="est_year"
                onChange={this.SetCustomeState}
                value={model.est_year}
                type="number"
              />
            </div>
            <div className="input-field col s12 l6">
              <label
                htmlFor="nirf_rank"
                className={model.nirf_rank ? "active" : ""}
              >
                NIRF Rank
              </label>
              <input
                id="nirf_rank"
                name="nirf_rank"
                onChange={this.SetCustomeState}
                value={model.nirf_rank}
                type="number"
              />
            </div>
            <div className="input-field col s12 l6">
              <label>
                <input
                  id="is_naac"
                  name="is_naac"
                  type="checkbox"
                  onChange={this.SetCustomeStateBool}
                  checked={model.is_naac}
                />
                <span>IS NAAC</span>
              </label>
            </div>
            {model.is_naac && (
              <div className="input-field col s12 l7">
                <label
                  htmlFor="naac_grade"
                  className={model.naac_grade && "active"}
                >
                  NAAC Grade
                </label>
                <input
                  required
                  id="naac_grade"
                  name="naac_grade"
                  onChange={this.SetCustomeState}
                  value={model.naac_grade}
                  type="text"
                />
              </div>
            )}
          
          </div>

          <div className="file-field input-field col s6">
              <div className="btn">
                <span>Logo</span>
                <input type="file" accept="image/*" onChange={this.SetLogoFile} />
              </div>
              <div className="file-path-wrapper">
                <input className="file-path validate valid" type="text" />
              </div>
            </div>

            <div className="file-field input-field col s6">
              <div className="btn">
                <span>Cover Image</span>
                <input type="file" accept="image/*" onChange={this.SetCoverFile} />
              </div>
              <div className="file-path-wrapper">
                <input className="file-path validate valid" type="text" />
              </div>
            </div>
          <h5 className="text-xl text-black font-extrabold my-3">Address</h5>
          <div
            className="row"
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#eee",
            }}
          >
            <div className="input-field  col s12 l6" data-select2-id={111}>
              <select
                name="country_id"
                value={model.country_id}
                onChange={this.SetCustomeState}
                className="select2 browser-default"
                data-select2-id={1}
                tabIndex={-1}
                aria-hidden="true"
              >
                <option value="" data-select2-id={3}>
                  ---Select Country---
                </option>
                {model.countries.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-field  col s12 l6" data-select2-id={111}>
              <select
                name="state_id"
                value={model.state_id}
                onChange={this.SetCustomeState}
                className="select2 browser-default"
                data-select2-id={1}
                tabIndex={-1}
                aria-hidden="true"
              >
                <option value="" data-select2-id={3}>
                  ---Select State---
                </option>
                {model.states.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-field  col s12 l6" data-select2-id={111}>
              <select
                name="district_id"
                value={model.district_id}
                onChange={this.SetCustomeState}
                className="select2 browser-default"
                data-select2-id={1}
                tabIndex={-1}
                aria-hidden="true"
              >
                <option value="" data-select2-id={3}>
                  ---Select District---
                </option>
                {model.districts.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-field  col s12 l6" data-select2-id={111}>
              <select
                name="city_id"
                value={model.city_id}
                onChange={this.SetCustomeState}
                className="select2 browser-default"
                data-select2-id={1}
                tabIndex={-1}
                aria-hidden="true"
              >
                <option value="" data-select2-id={3}>
                  ---Select District---
                </option>
                {model.cities.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-field col s12 l12">
              <label htmlFor="address" className={model.address && "active"}>
                Address
              </label>
              <textarea
                className="materialize-textarea"
                required
                id="address"
                name="address"
                value={model.address}
                onChange={this.SetCustomeState}
              ></textarea>
            </div>
          </div>
          <h5 className="text-xl text-black font-extrabold my-3">Contact</h5>
          <div
            className="row"
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#eee",
            }}
          >
            <div className="input-field col s12 l6">
              <label
                htmlFor="contact_no"
                className={model.contact_no && "active"}
              >
                Contact Number
              </label>
              <input
                required
                id="contact_no"
                name="contact_no"
                onChange={this.SetCustomeState}
                value={model.contact_no}
                type="text"
              />
            </div>
            <div className="input-field col s12 l6">
              <label
                htmlFor="alternative_contact_no"
                className={model.alternative_contact_no && "active"}
              >
                Alternative Contact Number
              </label>
              <input
                id="alternative_contact_no"
                name="alternative_contact_no"
                onChange={this.SetCustomeState}
                value={model.alternative_contact_no}
                type="text"
              />
            </div>
            <div className="input-field col s12 l6">
              <label htmlFor="email" className={model.email && "active"}>
                Email
              </label>
              <input
                id="email"
                name="email"
                onChange={this.SetCustomeState}
                value={model.email}
                type="email"
              />
            </div>
            <div className="input-field col s12 l6">
              <label
                htmlFor="alternative_email"
                className={model.alternative_email && "active"}
              >
                Alternative Email
              </label>
              <input
                id="alternative_email"
                name="alternative_email"
                onChange={this.SetCustomeState}
                value={model.alternative_email}
                type="email"
              />
            </div>
          </div>
          <div className="input-field col s12">
            <button
              className="btn waves-effect waves-light right bg-sky-500"
              type="submit"
              name="action"
            >
              Submit
              <i className="material-icons right">
                {model.id ? "edit" : "add"}
              </i>
            </button>
          </div>
        </form>
        <MasterControl data={model} />
      </>
    );
  }
}
