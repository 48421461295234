import { ReactNode } from "react";
import MasterControl from "../../../Components/MasterControl";
import { TextCreateUpdate, withParams } from "../../../Components/PageHelper";
import BaseComponent from "../../../Core/BaseComponent";
import BaseCore from "../../../Core/BaseCore";
import { BaseResponse } from "../../../Core/BaseResponse";
import BaseState from "../../../Core/BaseState";
import UserService from "../../../DataServices/Super/UserService";
import UserModel, { UserRole, UserStatus } from "../../../Models/UserModel";
import UserCreateUpdateViewModel from "../../../ViewModels/User/UserCreateUpdateViewModel";
class UserCreateUpdatePage extends BaseComponent<
  any,
  UserCreateUpdateViewModel
> {
  constructor(props: any) {
    super(props);
    this.state = new BaseState<UserCreateUpdateViewModel>(
      new UserCreateUpdateViewModel()
    );
  }
  componentDidMount() {
    var id = this.props?.params?.id;
    var model = this.state.model;
    model.roles = BaseCore.ToEnumList(UserRole);
    model.statuses = BaseCore.ToEnumList(UserStatus);
    this.UpdateViewModel();
    if (id) {
      this.Details(id);
    }
  }
  Details(id: number) {
    this.ShowDataLoader(true);
    UserService.Details({ id: id }, (res: BaseResponse<any>) => {
      this.ShowDataLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      BaseCore.ObjectAssign(this.state.model, res.data);
      this.UpdateViewModel();
    });
  }
  Create = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new UserModel());
    this.ShowDataSubmitLoader(true);
    UserService.Create(model, (res: BaseResponse<any>) => {
      this.ShowDataSubmitLoader(false);
      console.log(res.errors);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.GoBack();
    });
  };
  Update = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new UserModel());
    if (!window.confirm("Are you sure ?")) {
      return false;
    }
    this.ShowDataSubmitLoader(true);
    UserService.Update(model, (res: BaseResponse<any>) => {
      this.ShowDataSubmitLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.GoBack();
    });
  };
  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <div className="row">
          <div
            id="breadcrumbs-wrapper"
            data-image={`${process.env.PUBLIC_URL}/assets/images/gallery/breadcrumb-bg.jpg`}
          >
            <div className="container">
              <div className="row">
                <div className="col s12 m6 l6">
                  <h5 className="breadcrumbs-title mt-0 mb-0">
                    <span>User</span>
                  </h5>
                </div>
                <div className="col s12 m6 l6 right-align-md">
                  <ol className="breadcrumbs mb-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Master</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#!">User</a>
                    </li>
                    <li className="breadcrumb-item active">
                      {TextCreateUpdate(model.id)}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div id="input-fields" className="card card-tabs">
                <div className="card-content">
                  <div className="card-title">
                    <div className="row">
                      <div className="col s12 m6 l6">
                        <h4 className="card-title">
                          User {TextCreateUpdate(model.id)}
                        </h4>
                      </div>
                      <div className="col s12 m6 l6">
                        <button className="btn right" onClick={this.GoBack}>
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="view-input-fields">
                    <div className="row">
                      <div className="col s12">
                        <form
                          className="formValidate0"
                          autoComplete="off"
                          onSubmit={model.id ? this.Update : this.Create}
                        >
                          <div className="row">
                            <div className="input-field col s12 l6">
                              <label
                                htmlFor="name"
                                className={model.name && "active"}
                              >
                                {" "}
                                Name
                              </label>
                              <input
                                required
                                id="name"
                                name="name"
                                onChange={this.SetCustomeState}
                                value={model.name}
                                type="text"
                              />
                            </div>
                            <div className="input-field col s12 l6">
                              <label
                                htmlFor="user_name"
                                className={model.user_name && "active"}
                              >
                                User Name{" "}
                              </label>
                              <input
                                required
                                id="user_name"
                                name="user_name"
                                onChange={this.SetCustomeState}
                                value={model.user_name}
                                type="text"
                              />
                            </div>
                            <div className="input-field col s12 l6">
                              <label
                                htmlFor="contact_no"
                                className={model.contact_no && "active"}
                              >
                                {" "}
                                Contact Number
                              </label>
                              <input
                                id="contact_no"
                                name="contact_no"
                                onChange={this.SetCustomeState}
                                value={model.contact_no}
                                type="text"
                              />
                            </div>
                            <div className="input-field col s12 l6">
                              <label
                                htmlFor="email"
                                className={model.email && "active"}
                              >
                                Email{" "}
                              </label>
                              <input
                                id="email"
                                name="email"
                                onChange={this.SetCustomeState}
                                value={model.email}
                                type="email"
                              />
                            </div>
                            <div className="input-field col s12 l6">
                              <label
                                htmlFor="password"
                                className={model.password && "active"}
                              >
                                Password
                              </label>
                              <input
                                required={!model.id ? true : false}
                                id="password"
                                name="password"
                                onChange={this.SetCustomeState}
                                value={model.password}
                                type="password"
                              />
                            </div>
                            <div className="input-field col s12 l6">
                              <label
                                htmlFor="confirm_password"
                                className={model.confirm_password && "active"}
                              >
                                Confirm Password{" "}
                              </label>
                              <input
                                required={!model.id ? true : false}
                                id="confirm_password"
                                name="confirm_password"
                                onChange={this.SetCustomeState}
                                value={model.confirm_password}
                                type="password"
                              />
                            </div>
                            <div
                              className="input-field  col s12 l6"
                              data-select2-id={111}
                            >
                              <select
                                name="role"
                                value={model.role}
                                onChange={this.SetCustomeState}
                                className="select2 browser-default"
                                data-select2-id={1}
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value="" data-select2-id={3}>
                                  ---Select Role---
                                </option>
                                {model.roles.map((item) => {
                                  return (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              className="input-field  col s12 l6"
                              data-select2-id={111}
                            >
                              <select
                                name="status"
                                value={model.status}
                                onChange={this.SetCustomeState}
                                className="select2 browser-default"
                                data-select2-id={1}
                                tabIndex={-1}
                                aria-hidden="true"
                              >
                                <option value="" data-select2-id={3}>
                                  ---Select Status---
                                </option>
                                {model.statuses.map((item) => {
                                  return (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="input-field col s12">
                              <button
                                className="btn waves-effect waves-light right bg-sky-500"
                                type="submit"
                                name="action"
                              >
                                Submit
                                <i className="material-icons right">
                                  {model.id ? "edit" : "add"}
                                </i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MasterControl data={model} />
      </>
    );
  }
}
export default withParams(UserCreateUpdatePage);
