import BaseApi from '../../Core/BaseApi';

export default class CollegeCategoryService extends BaseApi {
  public static Categories(model: any, callBack: any) {
    this.GetRequest(`${this.SUPER_ADMIN_PATH}/college_category/categories`, model, null, callBack);
  }

  public static Check(model: any, callBack: any) {
    this.PostRequest(`${this.SUPER_ADMIN_PATH}/college_category/check`, model, null, callBack);
  }
}
