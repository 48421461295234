import { useEffect } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomImage from '../Components/CustomImage';
import { BaseResponse } from '../Core/BaseResponse';
import Constant from '../Core/Constant';
import Helper from '../Core/Helper';
import SessionHelper from '../Core/SessionHelper';
import UserService from '../DataServices/Super/UserService';
import UserModel, { UserRole } from '../Models/UserModel';
export default function SuperAdminLayout(props: any) {
  const auth = SessionHelper.GetSession<UserModel>();
  if (!auth) {
    return <Navigate to={Constant.LOGIN_PATH} />;
  }
  if (auth.role !== UserRole.super) {
    return Helper.NavigateRedirect(auth.role);
  }
  const Logout = () => {
    if (window.confirm('Do you want to logout?')) {
      UserService.Logout((res: BaseResponse<any>) => {
        SessionHelper.SetSession(null);
        if (!res.is_success) {
          toast.warning(res.message);
        } else {
          toast.success(res.message);
        }
        props.navigate(Constant.LOGIN_PATH);
      });
    }
  };
  return (
    <>
      <header className="page-topbar" id="header">
        <div className="navbar navbar-fixed">
          <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light">
            <div className="nav-wrapper">
              <div className="header-search-wrapper hide-on-med-and-down">
                <i className="material-icons">search</i>
                <input
                  className="header-search-input z-depth-2"
                  type="text"
                  name="Search"
                  placeholder="Explore Materialize"
                  data-search="template-list"
                />
                <ul className="search-list collection display-none" />
              </div>
              <ul className="navbar-list right ">
                <li className="dropdown-language">
                  <a
                    className="waves-effect waves-block waves-light translation-button"
                    href="#!"
                    data-target="translation-dropdown"
                  >
                    <span className="flag-icon flag-icon-gb" />
                  </a>
                </li>
                <li className="hide-on-med-and-down">
                  <a className="waves-effect waves-block waves-light toggle-fullscreen" href="#!">
                    <i className="material-icons">settings_overscan</i>
                  </a>
                </li>
                <li className="hide-on-large-only search-input-wrapper">
                  <a className="waves-effect waves-block waves-light search-button" href="#!">
                    <i className="material-icons">search</i>
                  </a>
                </li>
                <li>
                  <a
                    className="waves-effect waves-block waves-light notification-button"
                    href="#!"
                    data-target="notifications-dropdown"
                  >
                    <i className="material-icons w-[24px]">
                      notifications_none
                      <small className="notification-badge h-[15px] w-[15px] ">5</small>
                    </i>
                  </a>
                </li>
                <li>
                  {/* <div className="  ">
                    <div
                      style={{
                        position: 'relative',
                        right: '5px',
                        top: '-20px',
                      }}
                      className="avatar-status avatar-online my-auto"
                    >
                      <img src={`${process.env.PUBLIC_URL}/assets/images/avatar/avatar-7.png`} alt="avatar" />
                      <i />
                    </div>
                  </div> */}
                  <div
                    onClick={Logout}
                    className=" cursor-pointer my-auto shadow-red-500 text-black px-4  rounded-lg font-bold"
                  >
                    Logout
                  </div>
                </li>
              </ul>
              {/* translation-button*/}
              <ul className="dropdown-content" id="translation-dropdown">
                <li className="dropdown-item">
                  <a className="grey-text text-darken-1" href="#!" data-language="en">
                    <i className="flag-icon flag-icon-gb" /> English
                  </a>
                </li>
                <li className="dropdown-item">
                  <a className="grey-text text-darken-1" href="#!" data-language="fr">
                    <i className="flag-icon flag-icon-fr" /> French
                  </a>
                </li>
                <li className="dropdown-item">
                  <a className="grey-text text-darken-1" href="#!" data-language="pt">
                    <i className="flag-icon flag-icon-pt" /> Portuguese
                  </a>
                </li>
                <li className="dropdown-item">
                  <a className="grey-text text-darken-1" href="#!" data-language="de">
                    <i className="flag-icon flag-icon-de" /> German
                  </a>
                </li>
              </ul>
              {/* notifications-dropdown*/}
              <ul className="dropdown-content" id="notifications-dropdown">
                <li>
                  <h6>
                    NOTIFICATIONS<span className="new badge">5</span>
                  </h6>
                </li>
                <li className="divider" />
                <li>
                  <a className="black-text" href="#!">
                    <span className="material-icons icon-bg-circle cyan small">add_shopping_cart</span> A new order has
                    been placed!
                  </a>
                  <time className="media-meta grey-text darken-2" dateTime="2015-06-12T20:50:48+08:00">
                    2 hours ago
                  </time>
                </li>
              </ul>
              {/* profile-dropdown*/}
              <ul className="dropdown-content" id="profile-dropdown">
                <li>
                  <a className="grey-text text-darken-1" href="#!">
                    <i className="material-icons">person_outline</i> Profile
                  </a>
                </li>
                <li>
                  <a className="grey-text text-darken-1" href="#!">
                    <i className="material-icons">chat_bubble_outline</i> Chat
                  </a>
                </li>
                <li>
                  <a className="grey-text text-darken-1" href="#!">
                    <i className="material-icons">help_outline</i> Help
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a className="grey-text text-darken-1" href="#!">
                    <i className="material-icons">lock_outline</i> Lock
                  </a>
                </li>
                <li>
                  <a className="grey-text text-darken-1" href="#!" onClick={Logout}>
                    <i className="material-icons">keyboard_tab</i> Logout
                  </a>
                </li>
              </ul>
            </div>
            <nav className="display-none search-sm">
              <div className="nav-wrapper">
                <form id="navbarForm">
                  <div className="input-field search-input-sm">
                    <input
                      className="search-box-sm mb-0"
                      type="search"
                      required
                      id="search"
                      placeholder="Explore Materialize"
                      data-search="template-list"
                    />
                    <label className="label-icon" htmlFor="search">
                      <i className="material-icons search-sm-icon">search</i>
                    </label>
                    <i className="material-icons search-sm-close">close</i>
                    <ul className="search-list collection search-list-sm display-none" />
                  </div>
                </form>
              </div>
            </nav>
          </nav>
        </div>
      </header>
      {/* END: Header*/}
      <ul className="display-none" id="default-search-main">
        <li className="auto-suggestion-title">
          <a className="collection-item" href="#!">
            <h6 className="search-title">FILES</h6>
          </a>
        </li>
        <li className="auto-suggestion">
          <a className="collection-item" href="#!">
            <div className="display-flex">
              <div className="display-flex align-item-center flex-grow-1">
                <div className="avatar">
                  <CustomImage
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/pdf-image.png`}
                    width={24}
                    height={30}
                    alt="sample image"
                  />
                </div>
                <div className="member-info display-flex flex-column">
                  <span className="black-text">Two new item submitted</span>
                  <small className="grey-text">Marketing Manager</small>
                </div>
              </div>
              <div className="status">
                <small className="grey-text">17kb</small>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion">
          <a className="collection-item" href="#!">
            <div className="display-flex">
              <div className="display-flex align-item-center flex-grow-1">
                <div className="avatar">
                  <CustomImage
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/doc-image.png`}
                    width={24}
                    height={30}
                    alt="sample image"
                  />
                </div>
                <div className="member-info display-flex flex-column">
                  <span className="black-text">52 Doc file Generator</span>
                  <small className="grey-text">FontEnd Developer</small>
                </div>
              </div>
              <div className="status">
                <small className="grey-text">550kb</small>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion">
          <a className="collection-item" href="#!">
            <div className="display-flex">
              <div className="display-flex align-item-center flex-grow-1">
                <div className="avatar">
                  <CustomImage
                    src={`${process.env.PUBLIC_URL}/app-assets/images/icon/xls-image.png`}
                    width={24}
                    height={30}
                    alt="sample image"
                  />
                </div>
                <div className="member-info display-flex flex-column">
                  <span className="black-text">25 Xls File Uploaded</span>
                  <small className="grey-text">Digital Marketing Manager</small>
                </div>
              </div>
              <div className="status">
                <small className="grey-text">20kb</small>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion">
          <a className="collection-item" href="#!">
            <div className="display-flex">
              <div className="display-flex align-item-center flex-grow-1">
                <div className="avatar">
                  <CustomImage
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/jpg-image.png`}
                    width={24}
                    height={30}
                    alt="sample image"
                  />
                </div>
                <div className="member-info display-flex flex-column">
                  <span className="black-text">Anna Strong</span>
                  <small className="grey-text">Web Designer</small>
                </div>
              </div>
              <div className="status">
                <small className="grey-text">37kb</small>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion-title">
          <a className="collection-item" href="#!">
            <h6 className="search-title">MEMBERS</h6>
          </a>
        </li>
        <li className="auto-suggestion">
          <a className="collection-item" href="#!">
            <div className="display-flex">
              <div className="display-flex align-item-center flex-grow-1">
                <div className="avatar">
                  <CustomImage
                    className="circle"
                    src={`${process.env.PUBLIC_URL}/assets/images/avatar/avatar-7.png`}
                    width={30}
                    alt="sample image"
                  />
                </div>
                <div className="member-info display-flex flex-column">
                  <span className="black-text">John Doe</span>
                  <small className="grey-text">UI designer</small>
                </div>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion">
          <a className="collection-item" href="#!">
            <div className="display-flex">
              <div className="display-flex align-item-center flex-grow-1">
                <div className="avatar">
                  <CustomImage
                    className="circle"
                    src={`${process.env.PUBLIC_URL}/assets/images/avatar/avatar-8.png`}
                    width={30}
                    alt="sample image"
                  />
                </div>
                <div className="member-info display-flex flex-column">
                  <span className="black-text">Michal Clark</span>
                  <small className="grey-text">FontEnd Developer</small>
                </div>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion">
          <a className="collection-item" href="#!">
            <div className="display-flex">
              <div className="display-flex align-item-center flex-grow-1">
                <div className="avatar">
                  <CustomImage
                    className="circle"
                    src={`${process.env.PUBLIC_URL}/assets/images/avatar/avatar-10.png`}
                    width={30}
                    alt="sample image"
                  />
                </div>
                <div className="member-info display-flex flex-column">
                  <span className="black-text">Milena Gibson</span>
                  <small className="grey-text">Digital Marketing</small>
                </div>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion">
          <a className="collection-item" href="#!">
            <div className="display-flex">
              <div className="display-flex align-item-center flex-grow-1">
                <div className="avatar">
                  <CustomImage
                    className="circle"
                    src={`${process.env.PUBLIC_URL}/assets/images/avatar/avatar-12.png`}
                    width={30}
                    alt="sample image"
                  />
                </div>
                <div className="member-info display-flex flex-column">
                  <span className="black-text">Anna Strong</span>
                  <small className="grey-text">Web Designer</small>
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <ul className="display-none" id="page-search-title">
        <li className="auto-suggestion-title">
          <a className="collection-item" href="#!">
            <h6 className="search-title">PAGES</h6>
          </a>
        </li>
      </ul>
      <ul className="display-none" id="search-not-found">
        <li className="auto-suggestion">
          <a className="collection-item display-flex align-items-center" href="#!">
            <span className="material-icons">error_outline</span>
            <span className="member-info">No results found.</span>
          </a>
        </li>
      </ul>
      {/* BEGIN: SideNav*/}
      <aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-dark sidenav-active-rounded">
        <div className="brand-sidebar">
          <h1 className="logo-wrapper">
            <a className="brand-logo darken-1" href="#!">
              <CustomImage
                className="hide-on-med-and-down "
                src={`${process.env.PUBLIC_URL}/assets/images/logo/materialize-logo.png`}
                alt="materialize logo"
              />
              <CustomImage
                className="show-on-medium-and-down hide-on-med-and-up"
                src={`${process.env.PUBLIC_URL}/assets/images/logo/materialize-logo-color.png`}
                alt="materialize logo"
              />
              <span className="logo-text hide-on-med-and-down">Materialize</span>
            </a>
            <a className="navbar-toggler" href="#!">
              <i className="material-icons">radio_button_checked</i>
            </a>
          </h1>
        </div>
        <ul
          className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"
          id="slide-out"
          data-menu="menu-navigation"
          data-collapsible="accordion"
        >
          <li className="active bold">
            <a className="collapsible-header waves-effect waves-cyan">
              <i className="material-icons">settings_input_svideo</i>
              <span className="menu-title" data-i18n="Dashboard">
                Dashboard
              </span>
            </a>
            <div className="collapsible-body">
              <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                {/* <li><a href="">
                                    <i className="material-icons">radio_button_unchecked</i><span data-i18n="Analytics">Analytics</span></a>
                                </li> */}
              </ul>
            </div>
          </li>
          <li className="bold">
            <a className="collapsible-header waves-effect waves-cyan">
              <i className="material-icons">dvr</i>
              <span className="menu-title" data-i18n="Templates">
                Masters
              </span>
            </a>
            <div className="collapsible-body">
              <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/category/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Vertical">Categories</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/course/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Courses</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/department/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Departments</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/university/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Universities</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/designation/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Designations</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/facility/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Facilities</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a className="collapsible-header waves-effect waves-cyan">
              <i className="material-icons">dvr</i>
              <span className="menu-title" data-i18n="Templates">
                Coordinator
              </span>
            </a>
            <div className="collapsible-body">
              <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/country/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Vertical">Countries</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/state/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">States</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/district/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Districts</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/city/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Cities</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a className="collapsible-header waves-effect waves-cyan">
              <i className="material-icons">person_outline</i>
              <span className="menu-title" data-i18n="Pages">
                College
              </span>
            </a>
            <div className="collapsible-body">
              <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/college/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Colleges</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold">
            <a className="collapsible-header waves-effect waves-cyan">
              <i className="material-icons">person_outline</i>
              <span className="menu-title" data-i18n="Pages">
                User
              </span>
            </a>
            <div className="collapsible-body">
              <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                <li>
                  <Link
                    className="collapsible-header waves-effect waves-cyan"
                    to={`${Constant.SUPER_ADMIN_PATH}/user/list`}
                  >
                    <i className="material-icons">radio_button_unchecked</i>
                    <span data-i18n="Horizontal">Users</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div className="navigation-background" />
        <a
          className="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only"
          href="#!"
          data-target="slide-out"
        >
          <i className="material-icons">menu</i>
        </a>
      </aside>
      <div id="main">
        <Outlet />
      </div>
      <footer className="page-footer footer footer-static footer-light navbar-border navbar-shadow">
        <div className="footer-copyright">
          <div className="container">
            <span>
              &copy; 2022{' '}
              <a href="#!" target="_blank">
                Collegeforme
              </a>{' '}
              All rights reserved.
            </span>
            <span className="right hide-on-small-only">
              Design and Developed by <a href="#!">Collegeforme Team</a>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
