import BaseComponentCore from './BaseComponentCore';
import BaseViewModel, { Modal } from './BaseViewModel';
import { toast } from 'react-toastify';
import SessionHelper from './SessionHelper';
import UserModel from '../Models/UserModel';

export default abstract class BaseComponent<P, M> extends BaseComponentCore<P, M> {

  protected isLoadComponent: boolean = false;

  protected pages: number[] = [5, 25, 50];
  protected readonly AUTH = SessionHelper.GetSession<UserModel>();


  protected get SetConfig() {
    var model = this.state.model as unknown as BaseViewModel;
    const config = {
      onUploadProgress: (progressEvent: any) => {
        let progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        model.progress = progress;
        this.UpdateViewModelUnknown(model);
      }
    }
    return config;
  }


  ShowDataLoader(isLoading: boolean) {
    var model = this.state.model as unknown as BaseViewModel;
    model.is_data_loading = isLoading;
    this.UpdateViewModelUnknown(model);
  }

  ShowDataSubmitLoader(isLoading: boolean) {
    var model = this.state.model as unknown as BaseViewModel;
    model.is_data_submit = isLoading;
    this.UpdateViewModelUnknown(model);
  }

  ShowDataWithFileSubmitLoader(isLoading: boolean) {
    var model = this.state.model as unknown as BaseViewModel;
    model.is_data_with_file_submit = isLoading;
    this.UpdateViewModelUnknown(model);
  }

  OnChangeUpdate = (childState: M) => {
    Object.assign(this.state.model as any | M, childState);
    this.UpdateViewModel();
  }


  ShowModal = (data?: any) => {
    var model = this.state.model as unknown as BaseViewModel;
    model.modal_data = data;
    model.is_modal_open = true;
    this.UpdateViewModelUnknown(model);
  }

  ShowPreviewModal = (data?: Modal) => {
    var model = this.state.model as unknown as BaseViewModel;
    model.modal_data = data;
    model.is_preview_modal_open = true;
    this.UpdateViewModelUnknown(model);
  }



  ShowToast(msg: string, type?: "danger" | "success" | "warning") {
    switch (type) {
      case "danger":
        toast.error(msg);
        break
      case "success":
        toast.success(msg);
        break
      case "warning":
        toast.warning(msg);
        break
      default:
        toast.warning(msg);
        break
    }
  }


  CloseModal = () => {
    var model = this.state.model as unknown as BaseViewModel;
    model.is_modal_open = false;
    this.UpdateViewModelUnknown(model);
  }

  GoBack() {
    window.history.go(-1);
  }

}

