import { ReactNode } from "react";
import ListLoader from "../../../Components/ListLoader";
import MasterControl from "../../../Components/MasterControl";
import Pagination from "../../../Components/Pagination";
import BaseComponent from "../../../Core/BaseComponent";
import BaseCore from "../../../Core/BaseCore";
import { BaseResponse } from "../../../Core/BaseResponse";
import BaseState from "../../../Core/BaseState";
import Helper from "../../../Core/Helper";
import EmployeeService from "../../../DataServices/Super/EmployeeService";
import EmployeeModel from "../../../Models/EmployeeModel";
import EmployeeChildViewModel from "../../../ViewModels/College/EmployeeChildViewModel";
import { ImCross } from "react-icons/im";
import CustomImage from "../../../Components/CustomImage";
export default class EmployeeChildPage extends BaseComponent<
  any,
  EmployeeChildViewModel
> {
  constructor(props: any) {
    super(props);
    const { data } = props;
    this.state = new BaseState<EmployeeChildViewModel>(data);
  }
  componentDidMount() {
    this.InitializeForm();
  }
  // ToggleImage = () => {
  //   this.state.model.is_modal_open = !this.state.model.is_modal_open;
  //   this.UpdateViewModel();
  // };
  PreViewImage = (alt: any, url: any) => {
    this.state.model.is_image_preview = !this.state.model.is_image_preview;
    this.state.model.current_alt = alt;
    this.state.model.current_url = url;
    this.UpdateViewModel();
  };
  InitializeForm() {
    this.ShowDataLoader(true);
    var pathData = Helper.GetBasePathData();
    EmployeeService.InitializeForm(
      {
        college_id: this.state.model.college_id,
        page: pathData.page,
        per_page: pathData.per_page,
      },
      (res: BaseResponse<any>) => {
        this.ShowDataLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.state.model.pagination = res.data.employees;
        this.state.model.designations = res.data.designations;
        this.UpdateViewModel();
      }
    );
  }
  GetEmployees() {
    this.ShowDataLoader(true);
    var pathData = Helper.GetBasePathData();
    EmployeeService.List(
      {
        college_id: this.state.model.college_id,
        page: pathData.page,
        per_page: pathData.per_page,
      },
      (res: BaseResponse<any>) => {
        this.ShowDataLoader(false);
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.state.model.pagination = res.data;
        this.UpdateViewModel();
      }
    );
  }
  ToggleModal = (data: EmployeeModel | any = null) => {
    let model = this.state.model;
    model.is_modal_open = this.state.model.is_modal_open ? false : true;
    if (data) {
      model.id = data.id;
      model.designation_id = data.designation_id;
      model.name = data.name;
      model.contact = data.contact;
      model.email = data.email;
      model.qualifications = data.qualifications;
      model.experience_year = data.experience_year;
      model.experience_month = data.experience_month;
      model.status = data.status;
      model.additional_designations = data.additional_designations;
      model.profile_image_path=data.profile_image_path;
    } else {
      model.id = null as any;
      model.name = null as any;
      model.designation_id = null as any;
      model.contact = null as any;
      model.email = null as any;
      model.qualifications = null as any;
      model.experience_year = null as any;
      model.experience_month = null as any;
      model.status = null as any;
      model.profile_image_path=null as any;
      model.additional_designations = [];
    }
    this.UpdateViewModel();
  };
  SetFile = (event: any) => {
    this.state.model.file = event.target.files[0];
    this.UpdateViewModel();
    event.preventDefault();
    if (event.target.files) {
      const reader = new FileReader();
      const file = event.target.files[0];
      reader.onloadend = () => {
        this.state.model.profile_image_path = reader.result;
        this.UpdateViewModel();
      };
      reader.readAsDataURL(file);
    }
  };
  Create = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new EmployeeModel());
    this.ShowDataWithFileSubmitLoader(true);
    EmployeeService.Create(model, this.SetConfig, (res: BaseResponse<any>) => {
      this.ShowDataWithFileSubmitLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.GetEmployees();
      this.ToggleModal();
    });
  };
  Update = (event?: any) => {
    event.preventDefault();
    let model = BaseCore.CopyModel(this.state.model, new EmployeeModel());
    if (!window.confirm("Are you sure ?")) {
      return false;
    }
    this.ShowDataWithFileSubmitLoader(true);
    EmployeeService.Update(model, this.SetConfig, (res: BaseResponse<any>) => {
      this.ShowDataWithFileSubmitLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.GetEmployees();
      this.ToggleModal();
    });
  };
  Delete = (id: number) => {
    if (!window.confirm("Are you sure delete this item ?")) {
      return false;
    }
    EmployeeService.Delete({ id: id }, (res: BaseResponse<any>) => {
      if (!res.is_success) {
        this.ShowToast(res.message, "danger");
        return;
      }
      this.ShowToast(res.message, "success");
      this.GetEmployees();
    });
  };
  DeleteAdditionalDesignation = (id: number) => {
    if (!window.confirm("Are you sure delete this item ?")) {
      return false;
    }
    EmployeeService.DeleteAdditionalDesignation(
      { id: id },
      (res: BaseResponse<any>) => {
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.state.model.additional_designations = res.data;
        this.UpdateViewModel();
      }
    );
  };
  AddAdditionalDesignation = (id: any) => {
    EmployeeService.AddAdditionalDesignation(
      { designation_id: id, employee_id: this.state.model.id },
      (res: BaseResponse<any>) => {
        if (!res.is_success) {
          this.ShowToast(res.message, "danger");
          return;
        }
        this.state.model.additional_designations = res.data;
        this.UpdateViewModel();
      }
    );
  };
  render(): ReactNode {
    const model = this.state.model;
    return (
      <>
        <div className="row ">
          <button
            className="btn right  mx-4 mb-4 "
            onClick={() => this.ToggleModal(null)}
          >
            <div className="flex flex-wrap">Add</div>
          </button>
          <div>
            {!model.is_data_loading && (
              <table className="bordered highlight">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Qualifications</th>
                    <th>Designation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {model.pagination.data.map((item, index) => {
                    const ClickHandel = () => {
                      this.PreViewImage(item.name, item.profile_image_path);
                    };
                    return (
                      <>
                        <tr key={index}>
                          <td style={{ padding: "0 !important" }}>
                            <div>
                              <CustomImage
                                onClick={ClickHandel}
                                className="rounded-full aspect-square max-h-12 cursor-pointer"
                                src={item.profile_image_path}
                                alt={item.name}
                              />
                            </div>
                            {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            {this.state.model.isToggled ? "Hide" : "Show"}{" "}
                            Component
                          </button> */}
                          </td>
                          <td>{item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.email}</td>
                          <td>{item.qualifications}</td>
                          <td>{item.designation?.name}</td>
                          <td>
                            <i
                              className="material-icons green-text cursor-pointer m-1"
                              onClick={() => this.ToggleModal(item)}
                            >
                              edit
                            </i>
                            <i
                              className="material-icons red-text cursor-pointer m-1 "
                              onClick={() => this.Delete(item.id)}
                            >
                              delete{" "}
                            </i>
                          </td>
                        </tr>
                        {this.state.model.is_image_preview && (
                          <div className="fixed mx-auto my-auto z-50 inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-screen">
                              <div className="fixed inset-0 transition-opacity">
                                <div className="absolute inset-0 bg-gray-500 opacity-25"></div>
                              </div>
                              <div className="relative z-10 w-auto max-w-2xl bg-white  rounded-lg shadow-lg">
                                <div className="absolute top-0 right-0 pt-4 pr-4">
                                  <button
                                    className="text-white bg-black opacity-50  p-2 rounded-md hover:text-gray-600 hover:bg-white focus:outline-none"
                                    onClick={()=>this.PreViewImage(null,null)}
                                  >
                                    <ImCross />
                                  </button>
                                </div>
                                <CustomImage
                                  src={this.state.model.current_url}
                                  alt={this.state.model.current_alt}
                                  className=" aspect-square mx-auto my-auto rounded-lg "
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            )}
            {model.pagination.links.length > 3 && (
              <Pagination
                data={model.pagination}
                onChangeUpdate={this.OnChangeUpdate}
                body={{ college_id: model.college_id }}
              />
            )}
            {model.is_data_loading && <ListLoader />}
            <MasterControl data={model} />
          </div>
        </div>
        {model.is_modal_open && (
          <div
            id="modal1"
            className="modal"
            style={{ display: "block", top: 90, zIndex: 1000 }}
          >
            <div className="modal-content">
              <form
                className="formValidate0"
                autoComplete="off"
                onSubmit={model.id ? this.Update : this.Create}
              >
                <h5 style={{ color: "green" }}>
                  {model.id ? "Update" : "Add"} Employee
                </h5>
                <div className="center-align mt-5 mb-5 justify-center ">
                  {model.profile_image_path && (
                    <CustomImage
                      className="rounded-full mx-auto w-[150px] h-[150px]"
                      src={model.profile_image_path}
                      alt="Preview"
                    />
                  )}
                </div>
                <div
                  className="row"
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#eee",
                  }}
                >
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="name"
                      className={model.name ? "active" : ""}
                    >
                      Name
                    </label>
                    <input
                      id="name"
                      required
                      name="name"
                      value={model.name}
                      onChange={this.SetCustomeState}
                      type="text"
                    />
                  </div>
                  <div className="file-field input-field col s12 l6">
                    <div className="btn">
                      <span>Profile Pic</span>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={this.SetFile}
                      />
                    </div>
                    <div className="file-path-wrapper">
                      <input className="file-path validate valid" type="text" />
                    </div>
                  </div>
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="contact"
                      className={model.contact ? "active" : ""}
                    >
                      Contact
                    </label>
                    <input
                      id="contact"
                      name="contact"
                      value={model.contact}
                      onChange={this.SetCustomeState}
                      type="text"
                    />
                  </div>
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="email"
                      className={model.email ? "active" : ""}
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      value={model.email}
                      onChange={this.SetCustomeState}
                      type="text"
                    />
                  </div>
                  <div className="input-field col s12 l12">
                    <label
                      htmlFor="qualifications"
                      className={model.qualifications && "active"}
                    >
                      Qualifications
                    </label>
                    <textarea
                      className="materialize-textarea"
                      required
                      id="qualifications"
                      name="qualifications"
                      value={model.qualifications}
                      onChange={this.SetCustomeState}
                    ></textarea>
                  </div>
                  <div
                    className="input-field  col s12 l8"
                    data-select2-id={111}
                  >
                    <select
                      name="designation_id"
                      required
                      value={model.designation_id}
                      onChange={this.SetCustomeState}
                      className="select2 browser-default"
                      data-select2-id={1}
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <option value="" data-select2-id={3}>
                        ---Select Designation ---
                      </option>
                      {model.designations.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {model.id && (
                    <div
                      className="input-field  col s12 l8"
                      data-select2-id={111}
                    >
                      <select
                        className="select2 browser-default"
                        onChange={(e) =>
                          this.AddAdditionalDesignation(e.target.value)
                        }
                        data-select2-id={1}
                        tabIndex={-1}
                        aria-hidden="true"
                      >
                        <option value="" data-select2-id={3}>
                          ---Add Addintional Designation ---
                        </option>
                        {model.designations.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <div
                        className="mt-1"
                        style={{
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "#eee",
                          //   padding: ,
                        }}
                      >
                        {model.additional_designations.map((item, index) => {
                          return (
                            <div className="flex flex-wrap justify-between ">
                              <div
                                className="font-bold mx-2 collection-item"
                                key={index}
                              >
                                {item.designation?.name}{" "}
                                {/* <i
                                style={{
                                  fontSize: "20px",
                                  color: "red",
                                  marginTop: "0",
                                  marginBottom: "0",
                                  position: "unset",
                                }}
                                className=" material-icons "
                              >
                                delete
                              </i> */}
                              </div>
                              <div
                                onClick={() =>
                                  this.DeleteAdditionalDesignation(item.id)
                                }
                                className="text-white mx-2  my-1 cursor-pointer font-bold px-2 rounded-sm bg-red-500 "
                              >
                                {/* <i
                                style={{
                                  fontSize: "20px",
                                  color: "red",
                                  marginTop: "0",
                                  marginBottom: "0",
                                  position: "unset",
                                }}
                                className=" material-icons "
                              >
                                delete
                              </i> */}
                                X
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="experience_year"
                      className={model.experience_year ? "active" : ""}
                    >
                      Experience Year
                    </label>
                    <input
                      id="experience_year"
                      name="experience_year"
                      value={model.experience_year}
                      onChange={this.SetCustomeState}
                      type="text"
                    />
                  </div>
                  <div className="input-field col s12 l6">
                    <label
                      htmlFor="experience_month"
                      className={model.experience_month ? "active" : ""}
                    >
                      Experience Month
                    </label>
                    <input
                      id="experience_month"
                      name="experience_month"
                      value={model.experience_month}
                      onChange={this.SetCustomeState}
                      type="text"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    style={{ backgroundColor: "green", color: "white" }}
                    className=" modal-action modal-close waves-effect waves-green btn-flat"
                  >
                    {model.id ? "Update" : "Add"}
                  </button>
                  <button
                    onClick={this.ToggleModal}
                    style={{
                      marginLeft: 5,
                      backgroundColor: "red",
                      color: "white",
                    }}
                    className=" modal-action modal-close waves-effect waves-green btn-flat"
                  >
                    Exit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    );
  }
}
