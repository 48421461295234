import BaseModel from "../Core/BaseModel";

export default class UserModel extends BaseModel {
    name?: string;
    email?: string;
    contact_no?: string;
    user_name?: string;
    role?: any;
    register_origin?:any;
    status?: any;
    api_token?: string;

    //Request
    password?: string;
    confirm_password?: string;
}

export enum UserRole {
    super,
    admin,
    user,
    student
}

export enum UserStatus {
    inactive,
    active,
}

export enum UserRegisterOrigin {
    system,
    facebook,
    google
}