import Constant from "../../Core/Constant";
import PagePath from "../../Core/PagePath";
import DashboardPage from "../User/Dashboard/DashboardPage";

const path = Constant.USER_PATH;

export const UserMenuRoutes: Array<PagePath> = [
    new PagePath(`${path}`, DashboardPage, "Dashboard", "dashboard"),

];

export const UserRoutes: Array<PagePath> = [

];

export default UserRoutes;