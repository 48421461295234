import Constant from "../../Core/Constant";
import PagePath from "../../Core/PagePath";
import DashboardPage from "../Admin/Dashboard/DashboardPage";


const path = Constant.ADMIN_PATH;

export const AdminMenuRoutes: Array<PagePath> = [
    new PagePath(path, DashboardPage, "Dashboard", "dashboard"),

];

export const AdminRoutes: Array<PagePath> = [

];

export default AdminRoutes;