import BaseModel from "../Core/BaseModel";
import CategoryModel from "./CategoryModel";

export default class CourseModel extends BaseModel {
    slug?: string;
    category_id?: number;
    name?: string;
    image_path?: string;
    eligibility?:string;
    duration_year?:number;
    duration_month?:number;
    category?: CategoryModel;

    //Requset
    file?: File;
}