import { Navigate, Outlet } from "react-router-dom";
import Constant from "../Core/Constant";
import SessionHelper from "../Core/SessionHelper";
import UserModel from "../Models/UserModel";

export default function StudentLayout() {

    const auth = SessionHelper.GetSession<UserModel>();
    
    if (!auth) {
        return <Navigate to={Constant.LOGIN_PATH} />
    }

    return (
        <main>
            <Outlet />
        </main>
    )

}