import { ReactNode } from "react";
import BaseComponent from "../../../Core/BaseComponent";
import { BaseResponse } from "../../../Core/BaseResponse";
import CityService from "../../../DataServices/Super/CityService";
import CityModel from "../../../Models/CityModel";
import PaginationModel from "../../../Models/PaginationModel";
import CityListViewModel from "../../../ViewModels/City/CityListViewModel";
import Helper from "../../../Core/Helper";
import Pagination from "../../../Components/Pagination";
import { Link } from "react-router-dom";
import Constant from "../../../Core/Constant";
import MasterControl from "../../../Components/MasterControl";
import ListLoader from "../../../Components/ListLoader";
import BaseState from "../../../Core/BaseState";

export default class CityListPage extends BaseComponent<any, CityListViewModel> {

    constructor(props: any) {
        super(props);
        this.state = new BaseState<CityListViewModel>(new CityListViewModel());

    }

    componentDidMount(): void {

        if (!this.isLoadComponent) {
            this.isLoadComponent = true;
            this.GetList();
        }
    }

    GetList() {
        var pathData = Helper.GetBasePathData();
        this.ShowDataLoader(true);
        CityService.List({ page: pathData.page, per_page: pathData.per_page }, (res: BaseResponse<any>) => {
            this.ShowDataLoader(false);
            if (!res.is_success) {
                return this.ShowToast(res.message, "danger");
            }

            this.state.model.pagination = res.data as PaginationModel<CityModel>;
            this.UpdateViewModel();
        })
    }

    Delete = (id: number) => {

        if (!window.confirm("Are you sure delete this item ?")) {
            return false;
        }
        CityService.Delete({ id: id }, (res: BaseResponse<any>) => {
            if (!res.is_success) {
                this.ShowToast(res.message, "danger");
                return;
            }
            this.ShowToast(res.message, "success");
            this.GetList();
        });
    }


    render(): ReactNode {
        var model = this.state.model;

        return (
            <>
                <div className="row">
                    <div id="breadcrumbs-wrapper" data-image={`${process.env.PUBLIC_URL}/assets/images/gallery/breadcrumb-bg.jpg`}>
                        <div className="container">
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <h5 className="breadcrumbs-title mt-0 mb-0"><span>City</span></h5>
                                </div>
                                <div className="col s12 m6 l6 right-align-md">
                                    <ol className="breadcrumbs mb-0">
                                        <li className="breadcrumb-item"><a href="#!">Master</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="#!">City</a>
                                        </li>
                                        <li className="breadcrumb-item active">List
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div id="input-fields" className="card card-tabs">
                                <div className="card-content">
                                    <div className="card-title">
                                        <div className="row">
                                            <div className="col s12 m6 l6">
                                                <h4 className="card-title">City List</h4>
                                            </div>
                                            <div className="col s12 m6 l6">
                                                <Link to={`${Constant.SUPER_ADMIN_PATH}/city/create`} className="btn right">Add</Link>
                                            </div>

                                        </div>
                                    </div>
                                    <div id="view-input-fields">
                                        <div className="row">
                                            <div className="col s12">

                                                {!model.is_data_loading &&
                                                    <table className="bordered highlight">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Abbreviation</th>
                                                                <th>Country</th>
                                                                <th>State</th>
                                                                <th>District</th>
                                                                <td>Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {model.pagination.data.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.abbreviation}</td>
                                                                        <td>{item.country?.name}</td>
                                                                        <td>{item.state?.name}</td>
                                                                        <td>{item.district?.name}</td>
                                                                        <td>
                                                                            <Link to={`${Constant.SUPER_ADMIN_PATH}/city/update/${item.id}`} className="material-icons">edit</Link>
                                                                            <i className="material-icons red-text" onClick={() => this.Delete(item.id)}>delete</i>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                }
                                                {model.is_data_loading && <ListLoader />}

                                                {model.pagination.links.length > 0 &&
                                                    <Pagination data={model.pagination} onChangeUpdate={this.OnChangeUpdate} />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <MasterControl data={model} />

            </>
        )
    }
}