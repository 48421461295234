import { Fragment, ReactNode } from 'react';
import CustomImage from '../../../Components/CustomImage';
import MasterControl from '../../../Components/MasterControl';
import BaseComponent from '../../../Core/BaseComponent';
import { BaseResponse } from '../../../Core/BaseResponse';
import BaseState from '../../../Core/BaseState';
import CollegeCategoryService from '../../../DataServices/Super/CollegeCategoryService';
import CollegeCategoryChildViewModel from '../../../ViewModels/College/CollegeCategoryChildViewModel';

export default class CollegeCategoryChildPage extends BaseComponent<any, CollegeCategoryChildViewModel> {
  constructor(props: any) {
    super(props);
    const { data } = props;
    this.state = new BaseState<CollegeCategoryChildViewModel>(data);
  }

  componentDidMount() {
    this.GetCategories();
  }
  GetCategories() {
    this.ShowDataLoader(true);
    CollegeCategoryService.Categories({ college_id: this.state.model.college_id }, (res: BaseResponse<any>) => {
      this.ShowDataLoader(false);
      // debugger
      if (!res.is_success) {
        this.ShowToast(res.message, 'danger');
        return;
      }
      this.state.model.data = res.data;
      this.UpdateViewModel();
    });
  }
  Check = (Category_id?: number) => {
    let model = {
      category_id: Category_id,
      college_id: this.state.model.college_id,
    };
    this.ShowDataSubmitLoader(true);
    CollegeCategoryService.Check(model, (res: BaseResponse<any>) => {
      this.ShowDataSubmitLoader(false);
      if (!res.is_success) {
        this.ShowToast(res.message, 'danger');
        return;
      }
      this.ShowToast(res.message, 'success');
      this.GetCategories();
    });
  };

  render(): ReactNode {
    var model = this.state.model;
    return (
      <>
        <div className="row mt-1">
          <h5 className="text-xl text-black font-extrabold my-3">Categories</h5>
          <div className="grid grid-cols-3 " >
          {model.data?.map((data, index) => {
            return (
              <Fragment key={index}>
             
                  <div className="divide-y divide-gray-200 dark:divide-gray-700 m-2">
                  
                          <div className="text-sm flex  font-medium text-gray-900 truncate dark:text-white">
                            <div className="flex-shrink-0 justify-center">
                              <CustomImage
                                className="w-14 h-14 mx-auto rounded-full"
                                src={data.image_path}
                                alt={data.name}
                              />
                            </div>
                            <label className="ml-5 my-auto">
                              <input
                                id="Category"
                                name="Category"
                                type="checkbox"
                                checked={data.is_active}
                                onClick={() => this.Check(data.id)}
                              />
                              <span className="text-base">{data.name}</span>
                            </label>
                          </div>
                     
                </div>
              </Fragment>
            );
          })}
               </div>
        </div>
        <MasterControl data={model} />
      </>
    );
  }
}
