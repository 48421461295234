import { ReactNode } from 'react';
import CustomImage from '../../../Components/CustomImage';
import BaseComponent from '../../../Core/BaseComponent';
import { BaseResponse } from '../../../Core/BaseResponse';
import BaseState from '../../../Core/BaseState';
import CategoryService from '../../../DataServices/Super/CategoryService';
import CategoryModel from '../../../Models/CategoryModel';
import PaginationModel from '../../../Models/PaginationModel';
import CategoryListViewModel from '../../../ViewModels/Category/CategoryListViewModel';
import Helper from '../../../Core/Helper';
import Pagination from '../../../Components/Pagination';
import GridLoader from '../../../Components/GridLoader';
import { Link } from 'react-router-dom';
import Constant from '../../../Core/Constant';
import MasterControl from '../../../Components/MasterControl';

export default class CategoryListPage extends BaseComponent<
  any,
  CategoryListViewModel
> {
  constructor(props: any) {
    super(props);
    this.state = new BaseState<CategoryListViewModel>(
      new CategoryListViewModel()
    );
  }

  componentDidMount(): void {
    if (!this.isLoadComponent) {
      this.isLoadComponent = true;
      this.GetList();
    }
  }

  GetList() {
    var pathData = Helper.GetBasePathData();
    this.ShowDataLoader(true);
    CategoryService.List(
      { page: pathData.page, per_page: pathData.per_page },
      (res: BaseResponse<any>) => {
        this.ShowDataLoader(false);
        if (!res.is_success) {
          return this.ShowToast(res.message, 'danger');
        }

        this.state.model.pagination =
          res.data as PaginationModel<CategoryModel>;
        this.UpdateViewModel();
      }
    );
  }

  Delete = (id: number) => {
    if (!window.confirm('Are you sure delete this item ?')) {
      return false;
    }
    CategoryService.Delete({ id: id }, (res: BaseResponse<any>) => {
      if (!res.is_success) {
        this.ShowToast(res.message, 'danger');
        return;
      }
      this.ShowToast(res.message, 'success');
      this.GetList();
    });
  };

  render(): ReactNode {
    var model = this.state.model;

    return (
      <>
        <div className="row">
          <div
            id="breadcrumbs-wrapper"
            data-image={`${process.env.PUBLIC_URL}/assets/images/gallery/breadcrumb-bg.jpg`}
          >
            <div className="container">
              <div className="row">
                <div className="col s12 m6 l6">
                  <h5 className="breadcrumbs-title mt-0 mb-0">
                    <span>Category</span>
                  </h5>
                </div>
                <div className="col s12 m6 l6 right-align-md">
                  <ol className="breadcrumbs mb-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Master</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#!">Category</a>
                    </li>
                    <li className="breadcrumb-item active">List</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div id="input-fields" className="card card-tabs">
                <div className="card-content">
                  <div className="card-title">
                    <div className="row">
                      <div className="col s12 m6 l6">
                        <h4 className="card-title">Category List</h4>
                      </div>
                      <div className="col s12 m6 l6">
                        <Link
                          to={`${Constant.SUPER_ADMIN_PATH}/category/create`}
                          className="btn right"
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div id="view-input-fields">
                    <div className="row">
                      <div className="col s12">
                        {!model.is_data_loading && (
                          <div className="row">
                            {model.pagination.data.map((item, index) => {
                              return (
                                <div className="col s12 m6 l4" key={index}>
                                  <div className="card sticky-action">
                                    <div className="card-image waves-effect waves-block waves-light">
                                      <CustomImage
                                        className="activator"
                                        src={item.image_path}
                                        style={{ height: 250 }}
                                        alt={item.name}
                                      />
                                    </div>
                                    <div className="card-content">
                                      <Link
                                        to={`${Constant.SUPER_ADMIN_PATH}/category/update/${item.id}`}
                                        className="card-title activator grey-text text-darken-4"
                                      >
                                        {item.name}
                                        <i className="material-icons right">
                                          edit
                                        </i>
                                      </Link>

                                      <div
                                        className="card-title activator red-text text-darken-4"
                                        onClick={() => this.Delete(item.id)}
                                      >
                                        Delete this item
                                        <i className="material-icons right">
                                          delete
                                        </i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {model.is_data_loading && <GridLoader />}

                        {model.pagination.links.length > 0 && (
                          <Pagination
                            data={model.pagination}
                            onChangeUpdate={this.OnChangeUpdate}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MasterControl data={model} />
      </>
    );
  }
}
