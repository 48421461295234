import BaseModel from "../Core/BaseModel";
import CityModel from "./CityModel";
import CountryModel from "./CountryModel";
import DistrictModel from "./DistrictModel";
import StateModel from "./StateModel";

export default class UniversityModel extends BaseModel {
    slug?: string;
    name?: string;
    abbreviation?: string;
    country_id?: number;
    state_id?: number;
    district_id?: number;
    city_id?: number;
    address?: string;
    approved_by: any[] = [];
    type: any;
    logo_path?: string;
    est_date?: Date;
    est_year?: number;

    country?: CountryModel;
    state?: StateModel;
    district?: DistrictModel;
    city?: CityModel;

    //Requset
    file?: File;
}