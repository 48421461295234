import PageLoader from './PageLoader';

import PropTypes, { InferProps } from "prop-types";
import BaseViewModel from '../Core/BaseViewModel';

const ControlPropType = {
    data: PropTypes.instanceOf(BaseViewModel).isRequired
};


export default function MasterControl(props: InferProps<typeof ControlPropType >) {
    const { data } = props;
    var model = data;
    return (
        <div>
            <PageLoader data={model}></PageLoader>
        </div>
    );
}

MasterControl.defaultProps = ControlPropType;