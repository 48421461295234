import { ReactNode } from "react";
import MasterControl from "../Components/MasterControl";
import BaseComponent from "../Core/BaseComponent";
import BaseCore from "../Core/BaseCore";
import { BaseResponse } from "../Core/BaseResponse";
import BaseState from "../Core/BaseState";
import Constant from "../Core/Constant";
import Helper from "../Core/Helper";
import SessionHelper from "../Core/SessionHelper";
import UserService from "../DataServices/Super/UserService";
import UserModel, { UserRole } from "../Models/UserModel";
import UserLoginViewModel from "../ViewModels/User/UserLoginViewModel";

export default class UserLogin extends BaseComponent<any, UserLoginViewModel> {

    constructor(props: any) {
        super(props);
        this.state = new BaseState<UserLoginViewModel>(new UserLoginViewModel());
    }

    componentDidMount(): void {
        Helper.AppendLinkChild("assets/css/pages/login.css");
    }

    Login = (event: any) => {
        event.preventDefault();
        this.ShowDataSubmitLoader(true);
        var model = BaseCore.CopyModel(this.state.model, new UserModel());
        UserService.Login(model, (res: BaseResponse<any>) => {
            this.ShowDataSubmitLoader(false);
            if (!res.is_success) {
                return this.ShowToast(res.message, "warning");
            }

            let data = res.data as UserModel;
            SessionHelper.SetSession(data);
            let path = "";
            switch (data.role) {
                case UserRole.super:
                    path = Constant.SUPER_ADMIN_PATH;
                    break;
                case UserRole.admin:
                    path = Constant.ADMIN_PATH;
                    break;
                case UserRole.user:
                    path = Constant.USER_PATH;
                    break;
                case UserRole.student:
                    path = Constant.STUDENT_PATH;
                    break;
            }

            //this.props.navigate(path);
            window.location.href = path;

        })
    }



    render(): ReactNode {
        return (
            <>
                <div className="vertical-layout page-header-light vertical-menu-collapsible vertical-dark-menu preload-transitions 1-column login-bg   blank-page blank-page" data-open="click" data-menu="vertical-dark-menu" data-col="1-column">
                    <div className="row">
                        <div className="col s12">
                            <div className="container">
                                <div id="login-page" className="row">
                                    <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                                        <form className="login-form" onSubmit={this.Login} autoComplete={'off'}>
                                            <div className="row">
                                                <div className="input-field col s12">
                                                    <h5 className="ml-4">Sign in</h5>
                                                </div>
                                            </div>
                                            <div className="row margin">
                                                <div className="input-field col s12">
                                                    <i className="material-icons prefix pt-2">person_outline</i>
                                                    <input id="user_name" type="text" onChange={this.SetCustomeState} required />
                                                    <label htmlFor="username" className="center-align">Username</label>
                                                </div>
                                            </div>
                                            <div className="row margin">
                                                <div className="input-field col s12">
                                                    <i className="material-icons prefix pt-2">lock_outline</i>
                                                    <input id="password" type="password" onChange={this.SetCustomeState} required />
                                                    <label htmlFor="password">Password</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col s12 m12 l12 ml-2 mt-1">
                                                    <p>
                                                        <label>
                                                            <input type="checkbox" />
                                                            <span>Remember Me</span>
                                                        </label>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="input-field col s12">
                                                    <button type={'submit'} className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">Login</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="input-field col s6 m6 l6">
                                                    <p className="margin medium-small"><a href="#!">Register Now!</a></p>
                                                </div>
                                                <div className="input-field col s6 m6 l6">
                                                    <p className="margin right-align medium-small"><a href='#!'>Forgot password ?</a></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="content-overlay" />
                        </div>
                    </div>
                </div>
                <MasterControl data={this.state.model} />
            </>
        )
    }
}